








 


	
	import { Watch, Prop, Component, Vue } from 'vue-property-decorator';


    @Component({
        components: {
             
        },
	}) 
	export default class CheckBox extends Vue {

		@Prop( ) readonly name!: any
		@Prop( ) readonly label!: any
		@Prop( ) readonly form!: any
		@Prop( ) readonly estilo!: any
	}
 
