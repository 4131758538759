
import { RouteConfig } from 'vue-router' 


import ResumoIndex from '@/views/v2/geral/resumo/Index.vue';
import ResumoShow from '@/views/v2/geral/resumo/Show.vue';
 
import Modulos from '@/views/v2/geral/modulos/Modulos.vue'

import NoticiasIndex from '@/views/v2/geral/noticias/Index.vue'
import PagamentoIndex from '@/views/v2/geral/pagamento/Index.vue'
 
import BarraLateral from '@/components/layout/BarraLateral.vue' 
import Header from '@/components/layout/Header.vue'  
import Footer from '@/components/layout/Footer.vue'
 
import V2GraficoGeralIndex from '@/views/v2/relatorio/grafico/geral/Index.vue';
import V2GraficoDisciplinaIndex from '@/views/v2/relatorio/grafico/disciplina/Index.vue';
import Relatorio  from '@/views/v2/relatorio/index/Index.vue';

import V2EstatisticaDiaSemanaIndex  from '@/views/v2/relatorio/estatistica/dia_semana/Index.vue';
import V2EstatisticaHoraDiaIndex  from '@/views/v2/relatorio/estatistica/hora_dia/Index.vue';
import V2EstatisticaDisciplinaIndex  from '@/views/v2/relatorio/estatistica/disciplina/Index.vue';

import RelatoriConceito  from '@/views/v2/relatorio/conceitos/Index.vue';

import ConcursoRelacionado  from '@/views/v2/geral/prova_anterior/Pergunta.vue';


import PerguntaIndex  from '@/views/v2/geral/pergunta/Pergunta.vue';


import SimuladoHistoricoIndex  from '@/views/v2/geral/simulado_historico/Index.vue';
import SimuladoHistoricoResultado  from '@/views/v2/geral/simulado_historico/Resultado.vue';

import SimuladoIndex  from '@/views/v2/geral/simulado/Index.vue';
import SimuladoPerguntas  from '@/views/v2/geral/simulado/Pergunta.vue';




import ArquivoIndex from '@/views/v2/geral/arquivo/Index.vue';
import ArquivoShow from '@/views/v2/geral/arquivo/Show.vue'; 
import ArquivoEdit from '@/views/v2/geral/arquivo/Edit.vue';
import ArquivoCadastro from '@/views/v2/geral/arquivo/Cadastro.vue';

import ArquivoCompartilhadoIndex from '@/views/v2/geral/arquivo/compartilhados/Index.vue';


const routesGeral: Array<RouteConfig> = [
     
     

    {
		path: '/v2/geral/modulos',  
		components: {
			default: Modulos,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
	},

     



    {
		path: '/v2/geral/pagamento',  
		components: {
			default: PagamentoIndex,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
	},



 	/*
	|--------------------------------------------------------------------------
	| Noticias
	|--------------------------------------------------------------------------
	*/ 
	 
	{
		path: '/v2/geral/noticias', 
		components: {
			default: NoticiasIndex,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	}, 
	 


	
    /*
	|--------------------------------------------------------------------------
	| ARQUIVO
	|--------------------------------------------------------------------------
	*/ 
	 
	{
		path: '/v2/geral/arquivo', 
		components: {
			default: ArquivoIndex,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	}, 
	{
		path: '/v2/geral/arquivo/show/:id', 
		components: {
			default: ArquivoShow,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	},
	{
		path: '/v2/geral/arquivo/edit/:id', 
		components: {
			default: ArquivoEdit,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	}, 
	{
		path: '/v2/geral/arquivo/create', 
		components: {
			default: ArquivoCadastro,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	},   
	 


	{
		path: '/v2/geral/arquivo/compartilhados/:id', 
		components: {
			default: ArquivoCompartilhadoIndex,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	},
	





    /*
	|--------------------------------------------------------------------------
	| RESUMO
	|--------------------------------------------------------------------------
	*/ 
	 
	{
		path: '/v2/geral/resumo', 
		components: {
			default: ResumoIndex,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	}, 
	{
		path: '/v2/geral/resumo/:id', 
		components: {
			default: ResumoShow,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	},




    /*
	|--------------------------------------------------------------------------
	| ESTATISTICA	
	|--------------------------------------------------------------------------
	*/


	{
		path: '/v2/geral/relatorio/conceitos', 
		components: {
			default: RelatoriConceito,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	},

	

	{
		path: '/v2/geral/relatorio/grafico/geral', 
		components: {
			default: V2GraficoGeralIndex,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	},  
	{
		path: '/v2/geral/relatorio/grafico/disciplina', 
		components: {
			default: V2GraficoDisciplinaIndex,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	}, 
	
	
	{
		path: '/v2/geral/relatorio/estatistica/dia_semana', 
		components: {
			default: V2EstatisticaDiaSemanaIndex,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	},  
	{
		path: '/v2/geral/relatorio/estatistica/hora_dia', 
		components: {
			default: V2EstatisticaHoraDiaIndex,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	},  
	{
		path: '/v2/geral/relatorio/estatistica/disciplina', 
		components: {
			default: V2EstatisticaDisciplinaIndex,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	},  


	{
		path: '/v2/geral/relatorio', 
		components: {
			default: Relatorio,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	},  






    /*
	|--------------------------------------------------------------------------
	| PROVA ANTERIOR
	|--------------------------------------------------------------------------
	*/  
	{
		path: '/v2/geral/concurso_relacionados', 
		components: {
			default: ConcursoRelacionado,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	}, 
	 



	
    /*
	|--------------------------------------------------------------------------
	| PERGUNTAS
	|--------------------------------------------------------------------------
	*/  
	{
		path: '/v2/geral/perguntas', 
		components: {
			default: PerguntaIndex,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	}, 
	 



    /*
	|--------------------------------------------------------------------------
	| SIMULADO HISTORICO
	|--------------------------------------------------------------------------
	*/  
	{
		path: '/v2/geral/simulado/historico', 
		components: {
			default: SimuladoHistoricoIndex,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	}, 
	{
		path: '/v2/geral/simulado/historico/:id', 
		components: {
			default: SimuladoHistoricoResultado,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	}, 
	 
	 
    /*
	|--------------------------------------------------------------------------
	| SIMULADO
	|--------------------------------------------------------------------------
	*/  
	{
		path: '/v2/geral/simulado', 
		components: {
			default: SimuladoIndex,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	}, 
	{
		path: '/v2/geral/simulado/perguntas', 
		components: {
			default: SimuladoPerguntas,
			sidebar: BarraLateral,
			header: Header,
			footer: Footer,
		},
		meta: { requiresAuth: true}
	}, 
	 
	 
	 

]

  
 
export default routesGeral
