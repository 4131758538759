

















 

	import {Prop,Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class TreimentoParciais extends Vue { 

		@Prop( ) readonly respondidas!: any
		@Prop( ) readonly tema!: any 
	  
	  	get certas(): number {
			const total = this.respondidas.filter(function (numero: any) {
				return numero.resposta
			});				 	 
			return total.length;
		} 


	  	get erradas(): number {
			const total = this.respondidas.filter(function (numero: any) {
				return ! numero.resposta
			});				 	 
			return total.length;
		} 


	  	get rendimento(): string {
			const total = this.certas + this.erradas;
			let valor = 0 ; 
			if(total>0){
				valor = this.certas / total * 100  ; 
			}			 
			return valor.toFixed(1);
		} 

	}
 

