











































	
	import { resumoGeralService }  from '@/service'; 

	import { Watch, Component, Vue } from 'vue-property-decorator';

    import { namespace } from 'vuex-class' 
    const ambiente = namespace('ambiente')


    @Component({
        components: { },
    })
    export default class ResumoVisualizacao extends Vue { 

        

		@ambiente.State
		public modulo!: any


        private model: any = null ;
        private tema: any = false ;
 
		@Watch('tema')
		temaAlterado(newVal: any) {
			const temaColor: any = document.head.querySelector('meta[name="theme-color"]');
			if(this.tema){
				if(temaColor){
					temaColor.content= '#111';
				} 
			}
			else{
				if(temaColor){
					temaColor.content= '#00a65a';
				} 
			}  
		}



		created() { 
            if(!this.modulo){
				this.$router.push('/v2/geral/ambiente');
			} 
			window.alertProcessando(); 
			resumoGeralService.getResumoCurso( this.modulo,  this.$route.params.id )
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.alertProcessandoHide(); 
				window.toastErro('Não foi possivel achar Resumo');
            }); 
		}

		 


		mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper');

			if(header){ 
				header.hidden = true ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer');
			
			if(header){ 
				header.hidden = false ; 
			}

			if(footer){ 
				footer.hidden = false ; 
			}

			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
		}




	}

 

