







	import axios  from 'axios';  
  
	import { Watch, Prop, Component, Vue } from 'vue-property-decorator';


    @Component({
        components: {
             
        },
	}) 
	export default class ButtonExcluir extends Vue {

		@Prop( ) readonly url!: any
		@Prop( ) readonly texto!: any 


		alertConfimacao (  ) { 
			window.alertConfimacao('Confirma a Exclusão ','', this.excluirItem() );  

		}
  
		excluirItem() {
			axios.delete(this.url )
			.then(response => {
				window.toastSucesso('Item excluido com Sucesso ' ); 
				this.$router.push('/')
			})
			.catch(error => {
				console.log(error.response)
				window.toastErro(error);
			}); 
		} 


		get message(){
			if(this.texto){
				return this.texto;
			}
			return 'Excluir';
		}

	}

 

