<template>  
	<select ref="input" :id="id"> 
	</select>
</template>

<script>

	// import select2 from 'select2';
    import $ from "jquery" 

	export default {

		props:[
		'options' , 'value' , 'ajax' , 'id' , 'errors' , 'errors_texto'
		],

		mounted: function () {
			// var vm = this


			for (const prop in this.options) { 
            	this.$refs.input.setAttribute(  prop  , this.options[prop]  ); 
			}
		

			$(this.$el) 
			// .select2({ data: this.options, ajax: this.ajax })  
			.select2({  
				minimumInputLength: 3,  
				ajax: this.ajax,
				language:'pt-BR',
                placeholder: 'Buscar',
			})  
			

			.val(this.value)
			.trigger('change') 
			.on('change',   () => {  
				this.$emit('input', this.value)
			})


		},

		watch: {
			value: function (value) { 
				$(this.$el)  
				.val(value)
				.trigger('change')
			},

			options: function (options) { 
				$(this.$el).empty().select2({ data: options }) 
			},

			errors: function (errors) { 
				 if(errors){
					 $( "#" + this.id + " + span").addClass("is-invalid");
				 } 
				 else{
					$( "#" + this.id + " + span").removeClass("is-invalid");
				 }
			}

		}, 
		unmounted: function () {
			$(this.$el).off().select2('destroy')
		}
	}

</script>


<style >
 
.select2-container { 
    width: 100% !important;
}

.is-invalid .select2-selection,
.needs-validation ~ span > .select2-dropdown{
  border-color:red !important;
}
 
</style>
