/* eslint-disable @typescript-eslint/camelcase */ 

import { authHeader } from '..'; 

import { geralModel } from './index.model'; 
import axios  from 'axios'; 
 
import moment  from 'moment'; 
import * as CryptoJS from 'crypto-js';

const secret_key =  geralModel.getKeyCripto() ;
 
const url = geralModel.getUrl() + '/modulo' ; 
const urlResposta = geralModel.getApiResposta() ; 

import  store   from '@/store/index' 

function logout() {  
	store.dispatch('usuario/logout'); 
}

function getUrl(  ) { 
	return  url; 
}
 
function getAllDisponivel( api = false ) {  
	return  new Promise((resolve, reject) => {  
		const conceitos = localStorage.getItem('usuario_global_model_modulos_todos_disponivel' );  
		if(conceitos && !api ){  
			const data_criacao = localStorage.getItem('usuario_data_global_model_modulos_todos_disponivel'  );   
			if( moment() < moment( data_criacao ).add(1, 'days') ){
				const bytes  = CryptoJS.AES.decrypt(conceitos, secret_key ); 
				const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
				resolve(   dados  ); 
				return; 
			} 
		}   
		axios.get( url + '/disponiveis/para/usuarios' , {headers: authHeader() }  )
		.then(response => {   
			const encripted_conceitos = CryptoJS.AES.encrypt(JSON.stringify(response.data), secret_key ).toString(); 
			localStorage.setItem('usuario_global_model_modulos_todos_disponivel' , encripted_conceitos  );    
			localStorage.setItem('usuario_data_global_model_modulos_todos_disponivel'  , moment().format('YYYY-MM-DD')  ); 
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	});   
}
  
function getAll( api = false ) {  
	return  new Promise((resolve, reject) => {  
		const conceitos = localStorage.getItem('usuario_global_model_modulos_todos' );  
		if(conceitos && !api ){  
			const data_criacao = localStorage.getItem('usuario_data_global_model_modulos_todos'  );   
			if( moment() < moment( data_criacao ).add(1, 'days') ){
				const bytes  = CryptoJS.AES.decrypt(conceitos, secret_key ); 
				const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
				resolve(   dados  ); 
				return; 
			} 
		}   
		axios.get( url  , {headers: authHeader() }  )
		.then(response => {   
			const encripted_conceitos = CryptoJS.AES.encrypt(JSON.stringify(response.data), secret_key ).toString(); 
			localStorage.setItem('usuario_global_model_modulos_todos' , encripted_conceitos  );    
			localStorage.setItem('usuario_data_global_model_modulos_todos'  , moment().format('YYYY-MM-DD')  ); 
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	});   
}
  
function getModulo( id: any ) {  
	return  new Promise((resolve, reject) => {  
		const conceitos = localStorage.getItem('global_modelo_modulo_id_' + id );  
		if(conceitos){  
			const data_criacao = localStorage.getItem('data_global_modelo_modulo_id_' + id );   
			if( moment() < moment( data_criacao ).add(1, 'days') ){
				const bytes  = CryptoJS.AES.decrypt(conceitos, secret_key ); 
				const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
				resolve(   dados  ); 
				return;
			} 
		}    
		axios.get( url + '/' + id  , {headers: authHeader() }  )
		.then(response => {    
			const encripted_conceitos = CryptoJS.AES.encrypt(JSON.stringify(response.data), secret_key ).toString(); 
			localStorage.setItem('global_modelo_modulo_id_' + id , encripted_conceitos  );    
			localStorage.setItem('data_global_modelo_modulo_id_' + id , moment().format('YYYY-MM-DD')  ); 
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		})  
	}); 
}
  

function getDisciplinas(   modulo: any  ) {  
	const id = modulo.id;
	return  new Promise((resolve, reject) => {  
		const disciplinas = localStorage.getItem('global_modelo_disciplinas_todos_modulo_' + id );  
		if(disciplinas){  
			const data_criacao = localStorage.getItem('data_global_modelo_disciplinas_todos_modulo_'+ id );   
			if( moment()  < moment( data_criacao ).add(1, 'days') ){
				const bytes  = CryptoJS.AES.decrypt(disciplinas, secret_key ); 
				const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
				resolve(   dados  ); 
				return; 
			} 
		} 
		axios.get( modulo.url + 'api/v1/geral/modulo/' +  id + '/disciplinas'  , {headers: authHeader() }  )
			.then(response => {    
				const encripted_disciplinas = CryptoJS.AES.encrypt(JSON.stringify(response.data), secret_key ).toString(); 
				localStorage.setItem('global_modelo_disciplinas_todos_modulo_'  + id  , encripted_disciplinas  );    
				localStorage.setItem('data_global_modelo_disciplinas_todos_modulo_' + id  , moment().format('YYYY-MM-DD')  ); 
				resolve( response.data);  
			})
			.catch(error => {  
				console.log('passou aqui');
				if ( error.response.status === 401) {
					logout();				
				}
				reject(error.response);
			})   
	});  
}
 
function getPerguntasAssunto( modulo: any ,  assunto: any ,  disciplina: any ) { 
	const id = modulo.id; 
	return  new Promise((resolve, reject) => {
		axios.get( modulo.url + 'api/v1/geral/modulo/' + id+ '/assunto/' +  assunto + '/disciplina/' + disciplina +'/perguntas'    , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {
			if ( error.response.status === 401) {
				logout();				
			}  
			reject(error.response);
		}) 
	}); 
}
 
function getPerguntas( modulo: any ,  disciplina: any ) { 
	const id = modulo.id;  
	return  new Promise((resolve, reject) => {
		axios.get( modulo.url + 'api/v1/geral/modulo/' + id+ '/disciplina/' +  disciplina +'/perguntas'    , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
  
function responderApiResposta( modulo: any, data: any ) { 
	return  new Promise((resolve, reject) => {
		axios.post( modulo.urlresposta +  '/treinamento/responder'  , data  , {headers: authHeader() }  ) 
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
function responder( modulo: any, data: any ) { 
	return  new Promise((resolve, reject) => {
		axios.post(  modulo.urlresposta  + '/treinamento/responder'  , data  , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}


function pularQuestao(modulo: any, id: any ) { 
	return  new Promise((resolve, reject) => {
		axios.post(  modulo.urlresposta   + '/pular/questao/' + id  , {}  , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
function criarComentario( data: any ) { 
	return  new Promise((resolve, reject) => {
		axios.post( url + '/criar/comentario'  , data  , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
function getMinhasPerguntasSemanal( id: any , simulado: any) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id  + '/simulado/' + simulado  + '/respostas'  , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
  
function getTodasPerguntas( id: any  ) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id + '/simulado/perguntas'    , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
  
function getNoticias( modulo: any  ) { 
	const id = modulo.id;   
	return  new Promise((resolve, reject) => {
		axios.get( modulo.url + 'api/v1/geral/modulo/' + id + '/noticias'    , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
  
function responderSimulado( modulo: any, data: any ) {  
	return  new Promise((resolve, reject) => {
		axios.post(  modulo.urlresposta    + '/simulado/responder'  , data  , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
  

export const moduloGeralService = { 
	getUrl,
	getDisciplinas, 
	getPerguntasAssunto,
	getPerguntas,
	responder,
	pularQuestao,
	criarComentario,
	getMinhasPerguntasSemanal,
	getTodasPerguntas,
	responderSimulado,
	getModulo,
	getNoticias,
	getAll,
	getAllDisponivel,
	responderApiResposta,
 
};