






























	
	import { Watch, Prop, Component, Vue } from 'vue-property-decorator';


    @Component({
        components: {
             
        },
	}) 
	export default class Card extends Vue {

		@Prop( ) readonly config!: any
		 
		private min = true;
		private	exp= true;

		minimizar() {
			this.min = !this.min;
		}

		expandir() {
			this.exp = !this.exp;
		}
	}


 
