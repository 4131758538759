

































	import { moduloGeralService  }  from '@/service'; 
	import $ from "jquery"  
    import { namespace } from 'vuex-class' 
	import { Component, Vue } from 'vue-property-decorator'; 
    const ambiente = namespace('ambiente')


    @Component({
        components: { },
    })
    export default class ResumoMaterial extends Vue { 
 
		@ambiente.State
		public modulo!: any


        private noticias: any = null;

  
		created() {
            if(!this.modulo){
				this.$router.push('/v2/geral/ambiente');
			}
			else{
				this.buscarNoticias();
			}
        }

		buscarNoticias(){
            window.alertProcessando(); 
            moduloGeralService.getNoticias(this.modulo) 
			.then(response => {
				this.noticias = response ;
                 window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar as modulos');
                 window.alertProcessandoHide();
			}); 
        } 

	}
 
