

























/* eslint-disable @typescript-eslint/camelcase */ 
	
	import {  moduloGeralService    }  from '@/service';
 
	import _Parciais_Disciplina from './_Parciais_Disciplina.vue'; 
	import _Parciais from './_Parciais.vue'; 
	import _Relatorio from './_Relatorio.vue'; 
    
	import { namespace } from 'vuex-class' 
    const ambiente = namespace('ambiente')
   	
	import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { 
			'parciais-disciplina': _Parciais_Disciplina,  
            'parciais': _Parciais,  
			'relatorio': _Relatorio,   
		},
    })
    export default class SemanalHistoricoPergunta extends Vue { 


		@ambiente.State
		public modulo!: any


        private todas: any = null;
        private respondidas: any = null;
        private disciplinas: any = null;


		created() {  
			 if(!this.modulo){
				this.$router.push('/v2/geral/geral/modulos');
			}  
			else{
				this.getTodasDisciplinas();  
				this.getTodasPerguntas();  
			}
		}

		mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');

			if(header){ 
				header.classList.add("header-semanal-historico"); 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}

		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');

			if(header){
				header.classList.remove("header-semanal-historico"); 
			}
			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
		}





		setRespondidas( valores: any ) {
			this.respondidas =[];  
			for (let i = 0 ; i <= valores.length - 1 ; i++) {  
				this.respondidas.push({ 'pergunta' : valores[i].pergunta,'resposta':valores[i].acerto ,'resposta_id':valores[i].resposta_id  ,'disciplina': valores[i].disciplina_id });  
			}  
		}


		filterDisciplinas( valores: any , disciplina: any) { 
			if(valores){
				const perguntas = valores.filter(function (perg: any) { 
					return parseInt(perg.disciplina) === parseInt(disciplina)
				});	 
				return perguntas;
			}
			
		}

		getTodasDisciplinas() { 
			moduloGeralService.getDisciplinas( this.modulo )
			.then(response => {
				this.disciplinas = response; 
			})
			.catch(() => {  
				console.log('erro')
			}); 
		}


		//BUSCA TODAS AS PERGUNTA DA DISCIPLINA
		getTodasPerguntas() { 
			window.alertProcessando(); 
			moduloGeralService.getMinhasPerguntasSemanal( this.modulo.id , this.$route.params.id) 
			.then(response => {
				this.todas = response.perguntas ;  
				this.setRespondidas( response.respostas );   
				if(this.respondidas.length < 1 ){
					window.toastErro('Você provavelmente não respondeu nenhuma questão deste simulado!', '' );  
				}
				window.alertProcessandoHide();
			})
			.catch(error => { 
				window.alertProcessandoHide();
				this.$router.push('/v2/geral/simulado/historico'); 
				if( error.status == 423 ){ 
					window.toastErro('Erro: ', error.data ); 
				} 
				else{
					window.toastErro('Erro:', error.data.message); 
				}
			});
		}
 
	}
	 
