import { VuexModule, Module,  Mutation, Action } from 'vuex-module-decorators'
import {  moduloGeralService } from '@/service';  

// import  router  from '@/router';   
// const KJUR = require('jsrsasign');  

const KEY = 'ambiente-variavel-modulo';
const KEY_COMPRA = 'ambiente-variavel-modulo-compra';
const KEY_TEMA = 'ambiente-variavel-tema';

const modulo = localStorage.getItem(KEY);  
const moduloCompra = localStorage.getItem(KEY_COMPRA);  

const tema = localStorage.getItem(KEY_TEMA);  
 

@Module({
    namespaced: true, 
})

class User extends VuexModule {
      

    
    //=========================================================================================
    //================================ MODULO =================================================
    //=========================================================================================

    public modulo: any = modulo ?   JSON.parse( modulo ) : null; 

      

    @Mutation
    public alterarModuloMutation(  modulo: any ) {
        this.modulo = modulo; 
    }
    
 
    
    @Mutation 
    public resetModuloMutation( ) {  
        this.modulo = ''; 
    }



    @Action
    public alterarModulo(modulo: any) {  
        localStorage.removeItem(KEY);
        localStorage.setItem(KEY, JSON.stringify(modulo));
        this.context.commit('alterarModuloMutation', modulo )   
    }

 

    @Action
    public resetModulo( ): void { 
        localStorage.removeItem(KEY);
        this.context.commit('resetModuloMutation' ) ; 
        // router.push('/')
    }

    @Action
    public async buscarModulo(){ 
        moduloGeralService.getModulo( 2 ) 
			.then((response: any) => {
                localStorage.removeItem(KEY);
                localStorage.setItem(KEY, JSON.stringify(response));
                this.context.commit('alterarModuloMutation', response );   
			})
			.catch(error => { 
				console.log(error); 
			}); 
    }




    //=========================================================================================
    //================================ TEMA ===================================================
    //=========================================================================================


    public tema: boolean = tema ?   JSON.parse( tema ) : false; 

    @Mutation
    public alterarTemaMutation(  tema: boolean ) {
        this.tema = tema; 
    }
     
    @Mutation 
    public resetTemaMutation( ) {  
        this.tema = false ; 
    }
 
    @Action
    public alterarTema(tema: boolean) {  
        localStorage.removeItem(KEY_TEMA);
        localStorage.setItem(KEY_TEMA, JSON.stringify(tema));
        this.context.commit('alterarTemaMutation', tema )   
    }
 
    @Action
    public resetTema( ): void { 
        localStorage.removeItem(KEY_TEMA);
        this.context.commit('resetTemaMutation' ) ;  
    }

    








    
    //=========================================================================================
    //================================ MODULO =================================================
    //=========================================================================================

    public moduloAquisicao: any = moduloCompra ?   JSON.parse( moduloCompra ) : null; 

      

    @Mutation
    public alterarModuloCompraMutation(  modulo: any ) {
        this.moduloAquisicao = modulo; 
    }
    
 
    
    @Mutation 
    public resetModuloCompraMutation( ) {  
        this.moduloAquisicao = ''; 
    }



    @Action
    public alterarModuloCompra(modulo: any) {  
        localStorage.removeItem(KEY_COMPRA);
        localStorage.setItem(KEY_COMPRA, JSON.stringify(modulo));
        this.context.commit('alterarModuloCompraMutation', modulo )   
    }

 

    @Action
    public resetModuloCompra( ): void { 
        localStorage.removeItem(KEY_COMPRA);
        this.context.commit('resetModuloCompraMutation' ) ;  
    }


}
export default User