








































/* eslint-disable @typescript-eslint/camelcase */ 
	

	import {Prop,Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class TreimentoDisciplina extends Vue { 

		@Prop( ) readonly modulo!: any
		@Prop( ) readonly disciplinas!: any
		@Prop( ) readonly disciplina_atual!: any
		@Prop( ) readonly tema!: any
      
        private disciplina_atual_id: any =null ;
        private assunto_id: any =null ;




		enviarDisciplina() { 

			localStorage.setItem('geral_treinamento_modulo_' + this.modulo.id + '_disciplina',  this.disciplina_atual_id  );  
			
			if( this.assunto_id ){
				localStorage.setItem('geral_treinamento_modulo_' + this.modulo.id +'_assunto',  this.assunto_id  ); 
			}
			else{
				localStorage.removeItem('geral_treinamento_modulo_' + this.modulo.id +'_assunto'); 
			}
				
			this.$emit('mudouDisciplina',  { disciplina : this.disciplina_atual_id , assunto: this.assunto_id } );

		}



		emitiu(event: any){
			this.assunto_id = '';
		}
		


		get disciplina_atual_aqui(): any {
			if(this.disciplinas){
				for (let i = this.disciplinas.length - 1; i >= 0; i--) {
					if(this.disciplinas[i].id == this.disciplina_atual_id ){
						return this.disciplinas[i];
					}					
				} 
			} 
			return null; 
		} 


	}
	
 
 
	
