






































































































































import {  profileUsuarioService } from "@/service";

import { Component, Vue } from "vue-property-decorator";
 
import { namespace } from "vuex-class";
 

const ambiente = namespace("ambiente");

@Component({
  components: {   },
})
export default class Modulos extends Vue {
    
    public hasPlano = '' ;
    public valores = '' ;
    public pagamento = '' ;
    public pagamentoStatus = '' ;

    public meses = '' ;
    public picpay = '' ;
    public qrcode = '' ;
    
    @ambiente.State
    public moduloAquisicao!: any;
     
    @ambiente.Action
    public resetModuloCompra!: () => void;
 

    created() { 
        window.scrollTo(0, 0);
        if(!this.moduloAquisicao){
            this.$router.push('/');
        }  
        else{ 
            this.verifica();   
        } 
    }

    
    verifica() { 
        profileUsuarioService.PossuiPlano( this.moduloAquisicao.id )
        .then(response => {
            this.hasPlano = response.possui; 
            this.valores = response.valores; 
            if(this.hasPlano){
                window.toastErro('Você já possui o curso', ''); 
                this.$router.push('/');
            }
        })
        .catch(error => {
            window.toastErro('Não foi possivel verificar o Curso', error.data); 
        });
    }


    
    gerarQRCode() { 
        window.alertProcessando(); 
        profileUsuarioService.gerarQRCode( this.moduloAquisicao.id , this.meses )
        .then(response => { 
             this.picpay = response; 
             this.qrcode = response.qrcode; 
             this.pagamento = response.pagamento; 
             this.pagamentoStatus = response.pagamento.status;
             console.log(response);
             window.alertProcessandoHide();
             this.getPagamento();
        })
        .catch(error => {
            window.toastErro('Não foi possivel verificar o Curso', error.data); 
            window.alertProcessandoHide();
        });
    }


    
    getPagamento() {  
        if(this.pagamentoStatus === 'created' ){
            // window.alertProcessando(); 
            profileUsuarioService.getPagamento( this.pagamento.id   )
            .then(response => {  
                this.pagamento = response; 
                this.pagamentoStatus = response.status; 
                console.log(response);
                // window.alertProcessandoHide();
                setTimeout(() => {
                    this.getPagamento();
                }, 20000);
            })
            .catch(error => {
                window.toastErro('Não foi possivel verificar o Pagamento', error.data); 
                // window.alertProcessandoHide();
            });
        } 
    }


    beforeDestroy() {
        this.pagamentoStatus = 'finalizado';
    }











    destroyed() {
        this.resetModuloCompra();
    }
 
 
}
