

















import { Watch, Prop, Component, Vue } from 'vue-property-decorator';


    @Component({
        components: {
             
        },
	}) 
	export default class Menu extends Vue {

        @Prop({default: false} ) readonly active!: boolean

        @Prop({required: true}  ) readonly id!: string
        @Prop({default: 'fa fa-home'}  ) readonly icon!: string
        @Prop({default: ''}  ) readonly texto!: string


    }

 

