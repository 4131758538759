<template> 
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image"> 
            <img style="background-color:white;" src="/img/user.png" class="img-circle elevation-2" alt="User Image" v-if="!aberto" /> 
        </div> 
        <div class="info" v-bind:style="aberto ? 'white-space: pre-wrap;' : ''"  >
            <a href="#/profile" class="d-block"> <b> {{usuario.toUpperCase()}} </b></a>
        </div>
    </div> 
</template>

<script>

    import $ from "jquery" 


    export default {
        props: { 
            usuario: {
                type: String,
                default: ''
            },
        },

        mounted(){
            
            const $div = $("#body");
            const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === "class") {
                    this.menuOpen(); 
                }
            });
            });
            observer.observe($div[0], {
            attributes: true
            }); 
        },

        
        
		methods: {  
            menuOpen() {
                const body = document.querySelector("#body")  
                if( body.classList.contains("sidebar-collapse") ){ 
                    this.aberto = false;
                    return;
                }  
                this.aberto =  true;
            },
		},

        
		data() {
			return {     
				aberto: true , 
			}
		},

    }

</script>

<style scoped>

.user-panel{
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    padding-bottom: 5px !important;
}

b{
    font-weight: 700;
}
</style>
