







	import { Watch, Prop, Component, Vue } from 'vue-property-decorator';


    @Component({
        components: {
             
        },
	}) 
	export default class Button extends Vue {

		@Prop( ) readonly texto!: any
		@Prop( ) readonly classes!: any
		@Prop( ) readonly icone!: any
	}

 
