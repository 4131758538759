


















































 
  
    import { cadastroService   } from '@/service';

import { Component, Vue } from 'vue-property-decorator'; 
import { namespace } from 'vuex-class'; 
const usuario = namespace('usuario'); 


@Component({
  components: { 
  },
})
export default class Login extends Vue {
 

	@usuario.State
	public status!: any


	private username = "";

	created() {
		if(this.loggingIn){
			this.$router.push('/');
		} 
    }
 

	get loggingIn(): boolean {
		if (this.status) {
			return  this.status.loggedIn ;
		}
		return false;
	}



    recuperar() { 
        if(this.username){
            window.alertProcessando();  
            const dados = {
                cpf :this.username, 
            };   
            cadastroService.recuperSenha(  dados )
            .then(response => {
                window.toastSucesso(response.message);  
                window.alertProcessandoHide(); 
                this.$router.push('/login')
            })
            .catch(error => {  
                window.alertProcessandoHide(); 
                console.log(error);
            });  
        }
        
    } 
 
 
	mounted() { 
		const contentwrapper = document.getElementById('contentwrapper');  
		if(contentwrapper){
			contentwrapper.hidden = true ; 
		} 
	}


	destroyed(){   
		const contentwrapper = document.getElementById('contentwrapper');  
		if(contentwrapper){
			contentwrapper.hidden = false ; 
		}  
	}


}
 
