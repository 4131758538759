/* eslint-disable @typescript-eslint/camelcase */ 

import { authHeader } from '..';  
import { geralModel } from './index.model';  
import axios  from 'axios'; 
    
const url = geralModel.getUrl() + '/cadastro' ; 
  
function getUrl(  ) { 
	return  url; 
}
   
function recuperSenha( dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url  + '/reset/senha' , dados, {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {   
			reject(error.response);
		}) 
	}); 
}
 
function cadastrar(data: any ) { 
	return  new Promise((resolve, reject) => {
		axios.post( url , data )
		.then(response => {   
			resolve( response.data );  
		})
		.catch(error => {  
			reject(error.response.data);
		}) 
	}); 
}
 
export const cadastroService = { 
	getUrl,
    recuperSenha,
    cadastrar,
};