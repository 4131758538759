import { render, staticRenderFns } from "./Show.vue?vue&type=template&id=606b7809&scoped=true&"
import script from "./Show.vue?vue&type=script&lang=ts&"
export * from "./Show.vue?vue&type=script&lang=ts&"
import style0 from "./Show.vue?vue&type=style&index=0&id=606b7809&scoped=true&media=print&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "606b7809",
  null
  
)

export default component.exports