




















 

    import { Prop,Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class Relatorio extends Vue { 

		@Prop( ) readonly respondidas!: any
 

    }
