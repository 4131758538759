




























	/* eslint-disable @typescript-eslint/camelcase */ 

	import {Prop, Watch, Component, Vue } from 'vue-property-decorator'; 
	import { arquivoService  }  from '@/service'; 
	import $ from "jquery" ;

	@Component({
		components: { 
        },
	 })
    export default class FormArquivoCompartilhar extends Vue {
 
        private usuario: any = null;  
 

        private add_usuario_id = 'modal-geral-arquivo-adicionar-usuario'; 


        compartilhar() {
            if (this.usuario  ) {

                window.alertProcessando();
                const dados = {
                    usuario :this.usuario,   
                };   

                arquivoService.compartilhar( this.$route.params.id ,  dados )
                .then(response => {
                    window.toastSucesso("compartilhamento realizado com successo.");
                    window.alertProcessandoHide();
                    this.usuario = null;
                    this.$emit('arquivoCompartilhado', response);
                    ( $('#' + this.add_usuario_id) as any ) .modal('toggle');  
                })
                .catch(error => { 
                    window.toastErro(error.data.message);
                    window.alertProcessandoHide();  
                    console.log(error);
                });   
            }
        } 

    }
 
 
