









 
    /* eslint-disable @typescript-eslint/camelcase */ 
    import { Watch, Prop, Component, Vue } from 'vue-property-decorator';


    @Component({
        components: {
             
        },
	}) 
	export default class ProgressBars extends Vue {

		@Prop( {default: 'bg-success'}  ) readonly color!: string
		@Prop({default: '100'}  ) readonly valor!: string
		@Prop({default: ''}  ) readonly texto!: string
		@Prop({default: false }  ) readonly vertical!: boolean


        get style_bar(): any {
            if(this.vertical){
                return  { height: this.valor + '%' } ;
            }
            
            return  { width: this.valor + '%' } ;
        }


    }
    
 
