













 
	import {Prop, Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class SemanalInfo extends Vue { 


		@Prop( ) readonly pergunta!: any


		get positivas(): number {
			let valor = 0 ; 
			for (let i = 0 ; i <= this.pergunta.resposta.length - 1; i++) {
				if(  parseInt(this.pergunta.resposta[i].id ) ===  parseInt(this.pergunta.resposta_certa_id))
					valor +=  parseInt(this.pergunta.resposta[i].count);
			}
			return valor;
		}


		get negativas(): number {
			let valor = 0 ; 
			for (let i = 0 ; i <= this.pergunta.resposta.length - 1; i++) {
				if(  parseInt(this.pergunta.resposta[i].id) !==  parseInt(this.pergunta.resposta_certa_id))
					valor +=  parseInt(this.pergunta.resposta[i].count);
			}
			return valor;
		}



		get dificuldade(): string {
			const total = this.positivas + this.negativas;
			if(this.positivas / total > 0.9  ){
				return 'Muito Facil';
			}
			if(this.positivas / total > 0.8){
				return 'Facil';
			}
			if(this.positivas / total > 0.7 || ( total <= 3 )  ) {
				return 'Médio';
			}
			if(this.positivas / total > 0.6 || ( total <= 6 ) ){
				return 'Difícil';
			} 
			if(this.positivas / total > 0.5 || ( total <= 9 ) ){
				return 'Muito Difícil';
			} 
			if(this.positivas / total > 0.3){
				return 'Sobrenatural';
			} 
			if(this.positivas / total > 0.2){
				return 'Colossal';
			} 
			if(this.positivas / total > 0.1){
				return 'Utópica';
			} 
			if(this.positivas / total > 0.0){
				return 'Lendária';
			}  
			return ''; 
		}

	}
 
