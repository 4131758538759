








 
 
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const usuario = namespace('usuario');


@Component({
  components: { 
  },
})
export default class LoginToken extends Vue {


    @usuario.Action
	public loginDireto!: ( dados: any  ) => Promise<any>



    created() {
        window.alertProcessando();
		this.loginDireto(this.$route.params.token);
        this.$router.push('/');
        window.alertProcessandoHide();
    }
 


  
}
