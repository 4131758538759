import { VuexModule, Module,  Mutation, Action } from 'vuex-module-decorators'
import { moduloGeralService } from '@/service';  
 
const KJUR = require('jsrsasign');  

const KEY = 'gerente-variavel-modulo'; 
const KEY_DISCIPLINA = 'gerente-variavel-disciplina'; 
const KEY_ASSUNTO = 'gerente-variavel-assunto'; 

const modulo = localStorage.getItem(KEY);   
const disciplina = localStorage.getItem(KEY_DISCIPLINA);   
const assunto = localStorage.getItem(KEY_ASSUNTO);   
 

@Module({
    namespaced: true, 
})

class User extends VuexModule {
      

    
    //=========================================================================================
    //================================ MODULO =================================================
    //=========================================================================================

    public modulo: any = modulo ?   JSON.parse( modulo ) : null; 

      

    @Mutation
    public alterarModuloMutation(  modulo: any ) {
        this.modulo = modulo; 
    }
    
 
    
    @Mutation 
    public resetModuloMutation( ) {  
        this.modulo = ''; 
    }



    @Action
    public alterarModulo(modulo: any) {  
        localStorage.removeItem(KEY);
        localStorage.setItem(KEY, JSON.stringify(modulo));
        this.context.commit('alterarModuloMutation', modulo )   

        localStorage.removeItem(KEY_ASSUNTO);
        this.context.commit('resetDisciplinaMutation' ) ; 

        localStorage.removeItem(KEY_DISCIPLINA);
        this.context.commit('resetDisciplinaMutation' ) ;  
         
    }

 

    @Action
    public resetModulo( ): void { 
        localStorage.removeItem(KEY);
        this.context.commit('resetModuloMutation' ) ; 
        
        localStorage.removeItem(KEY_ASSUNTO);
        this.context.commit('resetDisciplinaMutation' ) ; 

        localStorage.removeItem(KEY_DISCIPLINA);
        this.context.commit('resetDisciplinaMutation' ) ;  

    }

    @Action
    public async buscarModulo(){ 
        moduloGeralService.getModulo( 2 ) 
			.then((response: any) => {
                localStorage.removeItem(KEY);
                localStorage.setItem(KEY, JSON.stringify(response));
                this.context.commit('alterarModuloMutation', response );   
			})
			.catch(error => { 
				console.log(error); 
			}); 
    }










    
    
    //=========================================================================================
    //================================ disciplina =================================================
    //=========================================================================================

    
    public disciplina: any = disciplina ?   JSON.parse( disciplina ) : null; 

      

    @Mutation
    public alterarDisciplinaMutation(  disciplina: any ) {
        this.disciplina = disciplina;  
    }
    
 
    
    @Mutation 
    public resetDisciplinaMutation( ) {  
        this.disciplina = '';  
    }



    @Action
    public alterarDisciplina(disciplina: any) {  
        localStorage.removeItem(KEY_DISCIPLINA);
        localStorage.setItem(KEY_DISCIPLINA, JSON.stringify(disciplina));
       
        
        localStorage.removeItem(KEY_ASSUNTO);
        this.context.commit('resetAssuntoMutation' ) ; 

        this.context.commit('alterarDisciplinaMutation', disciplina )   
    }

 

    @Action
    public resetDisciplina( ): void { 
        localStorage.removeItem(KEY_DISCIPLINA);
       
        
        localStorage.removeItem(KEY_ASSUNTO);
        this.context.commit('resetAssuntoMutation' ) ; 

        this.context.commit('resetDisciplinaMutation' ) ;  
    }

   
    
    
    //=========================================================================================
    //================================ assunto =================================================
    //=========================================================================================

    
    public assunto: any = assunto ?   JSON.parse( assunto ) : null; 

      

    @Mutation
    public alterarAssuntoMutation(  assunto: any ) {
        this.assunto = assunto; 
    }
    
 
    
    @Mutation 
    public resetAssuntoMutation( ) {  
        this.assunto = ''; 
    }



    @Action
    public alterarAssunto(assunto: any) {  
        localStorage.removeItem(KEY_ASSUNTO);
        localStorage.setItem(KEY_ASSUNTO, JSON.stringify(assunto));
        this.context.commit('alterarAssuntoMutation', assunto )   
    }

 

    @Action
    public resetAssunto( ): void { 
        localStorage.removeItem(KEY_ASSUNTO);
        this.context.commit('resetAssuntoMutation' ) ;  
    }

   

}
export default User