











    import { Watch, Prop, Component, Vue } from 'vue-property-decorator';


    @Component({
        components: {
             
        },
	}) 
	export default class TabNavBar extends Vue {

		@Prop({default: '' , required: true }  ) readonly title!: string
 
        @Prop({default: false }  ) readonly active!: boolean
        
		@Prop({default: '' , required: true }  ) readonly idcontent!: string 

    }

 

 

