



































































































































/* eslint-disable @typescript-eslint/camelcase */ 
	
	import {  moduloGeralService  }  from '@/service';
    import $ from "jquery"  

	  
	import _Parciais from './_Parciais.vue';
	import _Info from './_Info.vue';
	import _Relatorio from './_Relatorio.vue';
	import _classificacao from './_classificacao.vue';
  
	 

	import { Component, Vue , Watch } from 'vue-property-decorator';
      
	import { namespace } from 'vuex-class' 
    const ambiente = namespace('ambiente')


    @Component({
        components: {
			'parciais': _Parciais, 
            'info': _Info, 
			'relatorio': _Relatorio,  
			'classificacao': _classificacao,   
		},
    })
    export default class SemanalPergunta extends Vue { 

		@ambiente.State
		public modulo!: any


		@ambiente.State
		public tema!: any 
 
        private selected: any = null;
        private pergunta: any = null;
        private todas: any = null;
        private respondidas: any = null;
        private respondido: any = null;
        private concluido = false; 
        private chs: any = null;
        private tema_local = false;


		@ambiente.Action
        public alterarTema!: ( tema: boolean ) => void


		@Watch('tema_local')
		temaAlterado(newVal: any) {
			this.alterarTema(this.tema_local);
		}


		created() {   
			if(!this.modulo){
				this.$router.push('/v2/geral/geral/modulos');
			}  
			else{
				this.tema_local = this.tema;
				if(!this.modulo.has_simulado){
					this.$router.push('/');
				}
				else{
					this.getTodasPerguntas();  
				}
			} 
		}

		 


		mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');

			if(header){ 
				header.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');

			if(header){ 
				header.hidden = false ; 
			}
			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
		}



		setRespondidas( valores: any ) {
			this.respondidas =[];  
			for (let i = 0 ; i <= valores.length - 1 ; i++) {  
				this.respondidas.push({ 'pergunta' : valores[i].pergunta,'resposta':valores[i].acerto ,'resposta_id':valores[i].resposta_id   });  
			}  
		}



		//BUSCA TODAS AS PERGUNTA DA DISCIPLINA
		getTodasPerguntas() { 
			window.alertProcessando(); 
			moduloGeralService.getTodasPerguntas( this.modulo.id ) 
			.then((response: any) => {
				this.todas = response.perguntas ;  
				this.setRespondidas( response.respostas ); 
				this.proximaPergunta(); 
				window.alertProcessandoHide();
			})
			.catch(error => { 
				console.log(error);
				window.alertProcessandoHide(); 
				if( error.status == 423 ){ 
					window.toastErro('Erro: ', error.data ); 
				} 
				else{
					window.toastErro('Erro:', error.data.message); 
				}
			});
		}





		pularPergunta(){ 
			if(!this.respondido){
				this.todas.push( this.pergunta ); 
				this.proximaPergunta(   ); 
			} 
		}




		proximaPergunta(){ 
			this.selected = '' ; 
			this.respondido = false ;  
			window.scrollTo(0, 0); 
			
			if(this.todas.length > 0 ){
				this.pergunta = this.todas.shift() ; 
			}
			else{ 
				this.concluido = true ; 
				this.pergunta = null;
			} 
		}



		responder() {  
			if(!this.selected ){
				return;
			}
			
			if( parseInt( this.selected ) === parseInt( this.pergunta.resposta_certa_id ) ){	
				this.respondidas.push({'pergunta':this.pergunta,'resposta':true,'resposta_id': this.selected }); 
				window.alertSucesso("Você Acertou!!" , ""); 
			}  
			else{
				this.respondidas.push({'pergunta':this.pergunta,'resposta':false,'resposta_id': this.selected });  
				window.alertErro('Você Errou!', ''); 
			} 

			if(this.todas.length === 0 ){
				window.toastSucesso('Simulado Semanal Finalizado!!' ); 
			}

			this.respondido = true ; 

			const offset = $("#botao_proxima").offset();
			if(offset){
				$('html, body').animate({
				scrollTop:  offset.top  
			}, 0);
			}
			

			const data: any = {}; 
			data['pergunta_id'] = this.pergunta.id ; 
			data['selected'] = this.selected ;  
			
				
			data['disciplina_id'] = this.pergunta.disciplina_id  ;
			data['desafio_id'] = this.pergunta.desafio_id  ;
			data['tipo'] = 'Semanal' ;
			data['modulo_id'] = this.modulo.id  ; 

			moduloGeralService.responderSimulado( this.modulo , data)  
			.then(response => { 
				console.log('respondeu')
			});

		}



	}
	
  

