































/* eslint-disable @typescript-eslint/camelcase */ 
	

	import {Prop,Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class ConcursoAno extends Vue { 

		@Prop( ) readonly concursos!: any
		@Prop( ) readonly concurso_atual!: any 
		@Prop( ) readonly tema!: any
      
        private concurso_atual_id: any =null ; 


		enviarConcurso() {  
			this.$emit('mudouConcurso',  this.concurso_atual_id  ); 
		}
 
	}
	 
