


































	import {Prop,Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class TreimentoRelatorio extends Vue { 

		@Prop( ) readonly respondidas!: any 
		@Prop( ) readonly tema!: any 
	  
	} 

	 

