







































/* eslint-disable @typescript-eslint/camelcase */ 
    
    import { moduloGeralService   }  from '@/service';

	import { Component, Vue, Prop  } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class FormDisciplina extends Vue { 

        @Prop( ) readonly modulo!: any
		@Prop( ) readonly tema!: any

        private disciplina_atual: any = null ;

        private disciplinas: any = null ;

        private assunto: any =null ;


		get disciplina(): any {
			if(this.disciplina_atual){
				return JSON.parse( this.disciplina_atual )
			} 
			return {}; 
		}


		created() { 
			this.getDisciplinasAtivas();   
		}
		
		emitiu(event: any){
			this.assunto = '';
		}
		

		enviarDisciplina() {   
			if(this.disciplina_atual){
				this.$emit('mudouDisciplina',  { disciplina :  JSON.parse( this.disciplina_atual ) , assunto: this.assunto } );  
			}
		}
		 

		getDisciplinasAtivas() {  
			window.alertProcessando();   
			moduloGeralService.getDisciplinas( this.modulo )	 
			.then(response => {
				this.disciplinas = response ;  
				window.alertProcessandoHide();
			}) 
			.catch(error => {
				window.toastErro('Não foi possivel achar as disciplinas todas'); 
				window.alertProcessandoHide();
				 
			}); 
		}
			

	}
	
 
	
