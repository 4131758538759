
















    import { Watch, Prop, Component, Vue } from 'vue-property-decorator';


    @Component({
        components: {
             
        },
	}) 
	export default class Card extends Vue {

        @Prop( {default: 'bg-info'}  ) readonly color!: string
		@Prop({default: '#/' , required: true }  ) readonly link!: string
		@Prop({default: '' , required: true }  ) readonly title!: string
		@Prop({default: '' , required: true }  ) readonly subtitle!: string
		@Prop({default: 'fa fa-inbox' , required: true }  ) readonly icon!: string  

    }

 

