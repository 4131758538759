





 

	
	
	import { Watch, Prop, Component, Vue } from 'vue-property-decorator';


    @Component({
        components: {
             
        },
	}) 
	export default class ButtonVoltar extends Vue {

		@Prop( ) readonly url!: any
		@Prop( ) readonly texto!: any
		@Prop( ) readonly classes!: any
		@Prop( ) readonly icone!: any


		get message(){
			if(this.texto){
				return this.texto;
			}
			return 'Voltar';
		}

	}

	 

