






























/* eslint-disable @typescript-eslint/camelcase */ 

    import {   arquivoService } from '@/service';

	import $ from "jquery"  
 
    import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class IndexArquivo extends Vue { 

        private  reloadDatatable = false ; 
        private  token = '' ; 

        private config = {  
            order: [  [1, "asc"] ],
            ajax: {
                url: arquivoService.getUrl() + '/datatable',
            },
            columns: [ 
                { data: 'id', name: 'id',  class: 'text-center' },
                { data: 'nome',  name: 'nome'  },  
                { data: 'usuario.nome',  name: 'users.nome'  },  
                { data: 'action',  name: 'action', orderable: false, searchable: false,  class: 'text-center' }, 
            ],

        };
 


         created() {
            const temp_token = localStorage.getItem('user');
            if(temp_token){
                this.token =  JSON.parse(temp_token)   ;
            }
              
        }



        public tableatualizada( ) {  
             
             
            $('[btn-editar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                // window.open('#/arquivo/edit/' + id); 
                this.$router.push('/v2/geral/arquivo/edit/' + id);
            });
            $('[btn-visualizar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.$router.push('/v2/geral/arquivo/show/' + id);
            });
            
            $('[btn-compartilhar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.$router.push('/v2/geral/arquivo/compartilhados/' + id);
            });
            
            $('[btn-pdf]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                window.open( arquivoService.getUrl() + '/' + id + '/pdf?token=' + this.token);
            });

            $('[btn-pdf-dom]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                window.open( arquivoService.getUrl() + '/' + id + '/pdfdom?token=' + this.token);
            });

            $('[btn-desativar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Desativação ','' , 
                     () => {  
                        window.alertProcessando(); 
                        arquivoService.desativar(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('desativação realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel desativar ' , error.data.message); 
                        });  
                    }
                ); 
            });
    
            
    
            $('[btn-excluir]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id');  
                window.alertConfimacao('Confirma a Exclusão ','' , 
                     () => {  
                        window.alertProcessando(); 
                        arquivoService.excluir(  id )
                        .then(response => {
                            window.alertProcessandoHide();  
                            this.reloadDatatable = !this.reloadDatatable; 
                            window.toastSucesso('Exclusao realizada com sucesso!!' ); 
                        })
                        .catch(error => { 
                            window.alertProcessandoHide();
                            window.toastErro('Não foi possivel Excluir ' , error.data.message); 
                        });  
                    }
                ); 
            });

        }
    }
 
