































import { namespace } from 'vuex-class'
import { Component, Vue } from 'vue-property-decorator';


const usuario = namespace('usuario')

@Component({
    components: {
        
    },
})
export default class Header extends Vue {
    
  @usuario.State
  public user!: string


  @usuario.State
  public status!: any


  get loggingIn(): boolean {
      if (this.status) {
          return  this.status.loggedIn ;
      }
      return false;
  }

  @usuario.Action
  public logout!: ( ) => void

}





