






















import { Watch, Prop, Component, Vue } from 'vue-property-decorator';


    @Component({
        components: {
             
        },
	}) 
	export default class Carousel extends Vue {

        @Prop( {default: 'carroseul'}) readonly identificador!: string
        @Prop( ) readonly list!: any
    }


     

