
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';



require('datatables.net');
require('datatables.net-bs4');

 


require('datatables.net-responsive');
require('datatables.net-responsive-bs4');



window.moment = require('moment');
window.moment.locale('pt-BR');


window.iziToast = require('izitoast');
window.Swal = require('sweetalert2');
window.select2 = require('select2');
