







	import select2 from 'select2';
	import $ from "jquery"  
	import {Emit, Prop, Watch, Component, Vue } from 'vue-property-decorator'; 

	@Component({
		components: { 
        },
	 })
    export default class Select2 extends Vue {


		@Prop( ) readonly options!: any
		@Prop( ) value!: any
		// public value: any = null;
		@Prop( ) readonly propriedade!: any
		 
		 

		@Watch('value')
		valueAtualizado(value: any) {
			 $(this.$el).val(value).trigger('change')
		}


		@Watch('options')
		optionsAtualizad0(options: any) {
			$(this.$el).empty().select2({ data: options }) 
		}

		 
		mounted(){
			for (const prop in this.propriedade) { 
            	this.$refs.input.setAttribute(  prop  , this.propriedade[prop]  ); 
			}

			$(this.$el) 
			.select2({ data: this.options  })  
			.val(this.value)
			.trigger('change') 
			.on('change',   (teste) => {   
				this.changeValor( teste.currentTarget.value) 
			})
		}

		@Emit('input')
		changeValor(valor: any) {
			this.value = valor;
		}
		
		destroyed() { 
			$(this.$el).off().select2('destroy')
		}

	}
	 

