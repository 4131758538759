





































































/* eslint-disable @typescript-eslint/camelcase */ 
 
    import { arquivoService  } from '@/service';

    import Editor from '@tinymce/tinymce-vue';

	import { namespace } from 'vuex-class'
    import {Watch, Component, Vue } from 'vue-property-decorator'; 
	
	const ambiente = namespace('ambiente')

	@Component({
		components: { 
			'editor': Editor
        },
	 })
    export default class ArquivoCadastro extends Vue {

		@ambiente.State
		public modulo!: any

        
		private nome: any= null; 
        private descricao: any= null;
        private modulo_id: any= null;
        
 
		private urlRetorno: any= '/v2/geral/arquivo';

		private errors: any = { };
 
		
		created() {  
			if(!this.modulo){
				this.$router.push('/v2/geral/geral/modulos');
			}  
			else{
				this.modulo_id = this.modulo.id;
			} 
		}


		salvar() {
			window.alertProcessando();
			const dados = {
				nome :this.nome, 
				descricao :this.descricao,  
				modulo_id :this.modulo_id,  
			};   
			arquivoService.cadastrar(   dados )
			.then(response => {
				window.toastSucesso(response);  
				window.alertProcessandoHide();
				this.$router.push(  this.urlRetorno )
			})
			.catch(error => {  
				window.alertProcessandoHide();
				this.errors = error.data.error; 
				console.log(error);
			});  
		} 

        mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper');

			if(header){ 
                // header.hidden = true ; 
                header.style.marginLeft = "0px" ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer');
			
			if(header){ 
                // header.hidden = false ; 
                header.style.marginLeft = null ;   
			}

			if(footer){ 
				footer.hidden = false ; 
			}

			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
        }
        
        
    }
    
 
