








    import { Watch, Prop, Component, Vue } from 'vue-property-decorator';
    import Swal from 'sweetalert2';


    @Component({
        components: {
             
        },
	}) 
	export default class Alert extends Vue {
		
        @Prop({default: ''} ) readonly title!: string

		alert(){ 
			const Toast = Swal.mixin({
				toast: true,
				position: 'top-end',
				showConfirmButton: false,
				timer: 3000
			}); 
			// Toast.fire({
			// 	type: 'success',
			// 	title: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr.'
			// }); 
		}

	}

 
