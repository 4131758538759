




















	import { resumoGeralService  }  from '@/service';

	import $ from "jquery"  
    import { namespace } from 'vuex-class'

	import { Component, Vue } from 'vue-property-decorator';
      
    const ambiente = namespace('ambiente')


    @Component({
        components: { },
    })
    export default class ResumoMaterial extends Vue { 



		@ambiente.State
		public modulo!: any



        private config = {
			responsive: {
                details: false
            }, 
			order: [[ 3, "asc" ],[ 0, "asc" ]],
			ajax: { 
				url: resumoGeralService.getUrl()  
				// url: resumoGeralService.getUrl() + '/modulo/'+ this.$route.params.modulo_id  + '/datatable'
			},
			columns: [  
				{ data: 'titulo', name: 'titulo'  , class: 'all' }, 
				{ data: 'disciplina', name: 'disciplina.nome' , class: 'all' },  
				{ data: 'assunto', name: 'assunto.nome' , class: 'all' },  
				{ data: 'restrito', name: 'restrito' , class: 'never'}, 
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center all'}
			],
		};


		created() {
            if(!this.modulo){
				this.$router.push('/v2/geral/ambiente');
			}
			else{
				this.config.ajax.url = resumoGeralService.getUrl() + '/modulo/'+ this.modulo.id  + '/datatable';
			}
        }

		public tableatualizada( ) {   
            $('[btn-visualizar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.$router.push('/v2/geral/resumo/' + id  );
			});
		}


	}
 
