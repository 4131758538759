


















































	/* eslint-disable @typescript-eslint/camelcase */
	import {  relatorioGeralService   }  from '@/service';
 
    import { Component, Vue } from 'vue-property-decorator';
      
    import { namespace } from 'vuex-class' 
    const ambiente = namespace('ambiente')
      
    @Component({
        components: { },
    })
    export default class RelatorioIndex extends Vue { 
        

        @ambiente.State
		public modulo!: any


        private relatorio_dia_semana: any = null; 
        

        created() { 
            if(!this.modulo){
				this.$router.push('/v2/geral/ambiente');
			}  
            else{
                this.buscarRelatorioDiaSemana();  
            }
        }
  

        buscarRelatorioDiaSemana() { 
            window.alertProcessando();  
            relatorioGeralService.buscarTentativasUsuarioDiaSemana( this.modulo )   
			.then(response => {
				window.alertProcessandoHide();
				this.relatorio_dia_semana = response;
			})
			.catch(() => { 
				window.alertProcessandoHide(); 
            });  
        }
 

        get totalRespostaDiaSemana(): number {
            let total = 0; 
            for (let i = 0 ; i <= this.relatorio_dia_semana.length - 1; i++) { 
				total +=  parseInt(this.relatorio_dia_semana[i].count);
            }  
            return total;
        }
 

    }

 

