






 

	
	import { Watch, Prop, Component, Vue } from 'vue-property-decorator';


    @Component({
        components: {
             
        },
	}) 
	export default class Campo extends Vue {

		@Prop( ) readonly errors!: any
		@Prop( ) readonly errors_texto!: any 
	}

 
