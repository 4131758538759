


import { Line  } from 'vue-chartjs' 
import { Prop , Component  , Vue  } from 'vue-property-decorator';
    
@Component({ 
    extends: Line, 
})
export default class GraficoErros extends Vue<Line>  { 

    @Prop( ) readonly datacollection!: any
    
    public renderChart!: (chartData: any, options: any) => void


    private gradient: any = null ;
    private gradient2: any = null ;
    private gradient3: any = null ;
    private options: any = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{ 
                scaleLabel: {
                    display: true,
                    labelString: 'Mês'
                }, 
            }],
            yAxes: [{
                display: true, 
                scaleLabel: {
                    display: true,
                    labelString: 'Valor'
                },
                ticks: {
                    min: 0,
                    max: 100,
                    stepSize: 10,
                }
                    
            }]
        }
    }; 


     mounted() { 
        const canvas: any = this.$refs.canvas; 
        if(canvas){
            this.gradient = canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
            this.gradient2 = canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
            this.gradient3 = canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)

           this.gradient2.addColorStop(0, 'rgba(0, 0, 255, 0.3)')
            this.gradient2.addColorStop(0.5, 'rgba(0, 0, 255, 0.1)');
            this.gradient2.addColorStop(1, 'rgba(0, 0, 255, 0)');

            this.gradient.addColorStop(0, 'rgba(255, 0,0, 1)')
            this.gradient.addColorStop(0.5, 'rgba(255, 0, 0, 0.5)');
            this.gradient.addColorStop(1, 'rgba(255, 0, 0, 0.25)');

            this.gradient3.addColorStop(0, 'rgba(0, 255,0, 0.8)')
            this.gradient3.addColorStop(0.5, 'rgba(0, 255, 0, 0.3)');
            this.gradient3.addColorStop(1, 'rgba(0, 255, 0, 0)');

            this.renderChart(this.chartdata, this.options); 
        } 
    }


    get chartdata(): any {
        return {
            labels: this.label,
            datasets:  this.datasets
        };
    }



    get label(): any {
        const result = [];
        const map = new Map();  
        if(this.datacollection){
            for ( const  item of  this.datacollection) { 
                if(!map.has(item.label)){
                    map.set(item.label, true);     
                    result.push(
                        item.label
                    );
                }
            }
        }
        return result;
    }



    get datasets(): any {
        const result = [];
        const acerto = [];
        const erro = [];
        const map = new Map();  
        if(this.datacollection){
            for ( const  item of  this.datacollection) { 
                if(!map.has(item.label)){
                    map.set(item.label, true);     
                    if( parseInt(  item.count ) ){
                        result.push(
                            parseFloat( String( item.erro / item.count   * 100 ) ).toFixed(2) 
                        );
                    }
                    else{
                        result.push(
                                0
                        );
                    } 
                }
            }
        }
        const datasets = [
            {
                label: 'Erros %', 
                pointBackgroundColor: 'white',
                borderWidth: 1, 
                backgroundColor: this.gradient,
                borderColor: '#1010f9',
                data: result
            },
                    
        ];

        return datasets;
    }


}




