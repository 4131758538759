import { render, staticRenderFns } from "./_Relatorio.vue?vue&type=template&id=850bf266&scoped=true&"
import script from "./_Relatorio.vue?vue&type=script&lang=ts&"
export * from "./_Relatorio.vue?vue&type=script&lang=ts&"
import style0 from "./_Relatorio.vue?vue&type=style&index=0&id=850bf266&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "850bf266",
  null
  
)

export default component.exports