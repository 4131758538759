















	/* eslint-disable @typescript-eslint/camelcase */ 
	 
	import { profileUsuarioService } from "@/service"; 
	import { Component, Vue } from 'vue-property-decorator'; 

	   
	@Component({
		components: {  
        },
	})
	export default class FormTermoUso extends Vue {
  
		private add_plano_id = 'modal-seguranca-usuario-adicionar-plano'; 
		private termo =  ''   ; 
 
		created() {
			window.alertProcessando();
			
			profileUsuarioService.getTermoDeUso( ) 
			.then(response => {
				this.termo = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.toastErro('Não foi possivel achar o termo de uso', error.data);
				window.alertProcessandoHide(); 
			})
			.finally( () => {
				window.alertProcessandoHide();
			});
		}
		
		 
	}

