
















    import { Watch, Prop, Component, Vue } from 'vue-property-decorator';


    @Component({
        components: { 
        },
	}) 
	export default class NavTabs extends Vue { 
		
    }
     
