







import { Watch, Prop, Component, Vue } from 'vue-property-decorator';


    @Component({
        components: {
             
        },
	}) 
	export default class ContentNavabar extends Vue {

        @Prop({default: false} ) readonly active!: boolean
        @Prop( {required: true} ) readonly idcontent!: string


    }
     
