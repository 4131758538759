
















    import { Watch, Prop, Component, Vue } from 'vue-property-decorator';


    @Component({
        components: {
             
        },
	}) 
	export default class SubMenu extends Vue {

        @Prop( {default: true } ) readonly segundo!: boolean

		@Prop({default: '#/' , required: true }  ) readonly href!: string
		@Prop({default: ''  }  ) readonly menu!: string
		@Prop({default: ''  }  ) readonly texto!: string
		@Prop({default: ''  }  ) readonly badge!: string
		@Prop({default: 'badge-warning'  }  ) readonly badgeColor!: string
		@Prop({default: 'fas fa-share-alt text-navy '  }  ) readonly icon!: string


        menuDinamic() {

            // LIMPA OS SUB MENUS
            Array.from(document.querySelectorAll(".link2")).filter(function (value) {
                value.classList.remove("active");
            })

            const body = document.querySelector("#body")

            if ( body && body.classList.contains("sidebar-open")) {
                body.classList.remove("sidebar-open");
                body.classList.add("sidebar-collapse");
            }


            window.scrollTo(0, 0);

            // LIMPA OS MENUS
            Array.from(document.querySelectorAll(".link-menu")).filter(function (value) {
                value.classList.remove("active");
            })


            this.$refs.menulink.classList.add("active");

            if (this.menu) {
                const menu = document.getElementById(this.menu + '-link');
                if(menu){
                    menu.classList.add("active"); 
                }
            }



        }


    }
     

    