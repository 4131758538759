































































































































































/* eslint-disable @typescript-eslint/camelcase */ 
	 
	import {  moduloGeralService   }  from '@/service';
 
    import $ from "jquery"  
 
	import _Parciais from './_Parciais.vue';
	import _Info from './_Info.vue';
	import _Relatorio from './_Relatorio.vue'; 
	import _disciplina from './_disciplina.vue'; 
	  
	import { Component , Vue , Watch } from 'vue-property-decorator';
      
	import { namespace } from 'vuex-class' 
    const ambiente = namespace('ambiente')


    @Component({
        components: {
			'formDisciplina': _disciplina,  
            'parciais': _Parciais, 
            'info': _Info, 
			'relatorio': _Relatorio,   
		},
    })
    export default class Treinamento extends Vue { 


		@ambiente.State
		public modulo!: any 
		 
		@ambiente.State
		public tema!: any 
		 
        private selected: any = null;
        private pergunta: any = null;
        private todas: any = null;
        private respondidas: any = null;
        private respondido: any = null;
        private concluido = false; 
        private comentado = false; 
        private disciplina: any = null;
        private assunto: any = null; 
        private comentario: any = null;
        private rank: any = null;
        private disciplinas: any = null;
        private tema_local = false;


        // private count = 0;



		@ambiente.Action
        public alterarTema!: ( tema: boolean ) => void


		@Watch('tema_local')
		temaAlterado(newVal: any) {
			this.alterarTema(this.tema_local);
		}

		created() {  
			if(!this.modulo){
				this.$router.push('/v2/geral/geral/modulos');
			}  
			else{
				this.tema_local = this.tema;
				this.getDisciplinasAtivas();  
				this.getListaPerguntasRespondidas();  
				this.getTodasPerguntas();   
			} 
		}

		mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper');

			if(header){ 
				header.hidden = true ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer');
			
			if(header){ 
				header.hidden = false ; 
			}

			if(footer){ 
				footer.hidden = false ; 
			}

			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
		}


		get disciplina_atual(): string {
			if(this.disciplinas){
				for (let i = this.disciplinas.length - 1; i >= 0; i--) {
					if(this.disciplinas[i].id == this.disciplina ){
						return this.disciplinas[i].nome;
					}					
				} 
			} 
			return ''; 
		}



		mudouDisciplina(evento: any) {	 
			this.disciplina = evento.disciplina ;	
			this.assunto = evento.assunto ;	
			this.respondidas =[];
			this.concluido = false ;
			localStorage.removeItem('geral_treinamento_modulo_' + this.modulo.id + '_respondidas');
			localStorage.removeItem('geral_treinamento_modulo_' + this.modulo.id + '_todas');  
			this.getTodasPerguntas(); 
			
			
			// setTimeout(() => {
			// 	if(this.count < 90 ){
			// 		this.count++;
			// 		evento.disciplina = 10;
			// 		this.mudouDisciplina(evento ) ;

			// 		setTimeout(() => { 
			// 			this.count++;
			// 			evento.disciplina = 15;
			// 			this.mudouDisciplina(evento ) ; 
			// 		}, 5500); 

			// 		setTimeout(() => { 
			// 			this.count++;
			// 			evento.disciplina = 7;
			// 			this.mudouDisciplina(evento ) ; 
			// 		}, 3700); 

			// 		setTimeout(() => { 
			// 			this.count++;
			// 			evento.disciplina = 14;
			// 			this.mudouDisciplina(evento ) ; 
			// 		}, 2800); 

			// 	} 
			// }, 4000);  
		}



		//BUSCA TODAS AS DISCIPLINAS
		getDisciplinasAtivas() {   
			window.alertProcessando();   
			moduloGeralService.getDisciplinas(this.modulo)	 
			.then(response => {
				this.disciplinas = response ;  
				window.alertProcessandoHide();
			}) 
			.catch(error => {
				window.toastErro('Não foi possivel achar as disciplinas'); 
				window.alertProcessandoHide();
			}); 
		}


		//BUSCA A DISCIPLINA ATUAL
		getDisciplinaAtual() { 
			const disciplina = localStorage.getItem('geral_treinamento_modulo_' + this.modulo.id + '_disciplina');
			if (disciplina) {
				this.disciplina = JSON.parse( disciplina ) ; 
			}
			else{
				this.disciplina ='';
			} 	 
			const treinamento_assunto = localStorage.getItem( 'geral_treinamento_modulo_' + this.modulo.id +'_assunto');
			if (treinamento_assunto) {
				this.assunto = JSON.parse(treinamento_assunto ) ; 
			}
			else{
				this.assunto ='';
			} 	 
		}



		//BUSCA OS ID DAS PERGUNTAS RESPONDIDAS
		getListaPerguntasRespondidas() { 
			const respondidas = localStorage.getItem('geral_treinamento_modulo_' + this.modulo.id + '_respondidas');
			if (respondidas) {
				this.respondidas = JSON.parse( respondidas) ; 
			}
			else{
				this.respondidas =[];
			} 	 
		}



		//BUSCA TODAS AS PERGUNTA DA DISCIPLINA
		getTodasPerguntas() {	
			const todas = localStorage.getItem('geral_treinamento_modulo_' + this.modulo.id + '_todas');	 
			if (todas) {
				this.todas = JSON.parse( todas ) ; 
				this.proximaPergunta(); 
			}
			else if(this.disciplina){

				if(this.assunto){
					window.alertProcessando();  
					moduloGeralService.getPerguntasAssunto( this.modulo , this.assunto , this.disciplina ) 
					.then(response => {
						this.todas = response ; 
						if(this.todas && this.todas.length > 0 ){
							localStorage.setItem('geral_treinamento_modulo_' + this.modulo.id + '_todas', JSON.stringify( response ) );
							this.proximaPergunta(); 
							window.alertProcessandoHide();
						}
						else{
							window.alertProcessandoHide();
							window.alertErro('Este concurso não possui perguntas cadastradas no momento!, ou o usuário não possui o plano necessário!', '');   
						} 
					})
					.catch(error => { 
						console.log(error);
						window.alertProcessandoHide();
						if( error.status == 423 ){ 
							window.toastErro('Erro: ', error.data ); 
						} 
						else{
							window.toastErro('Não foi possivel achar as  perguntas'); 
						}
					});
				}
				else{
					window.alertProcessando();   
					moduloGeralService.getPerguntas( this.modulo , this.disciplina) 
					.then(response => {
						this.todas = response ; 
						if(this.todas && this.todas.length > 0 ){
							localStorage.setItem('geral_treinamento_modulo_' + this.modulo.id + '_todas', JSON.stringify( response ) );
							this.proximaPergunta(); 
							window.alertProcessandoHide();
						}
						else{
							window.alertProcessandoHide();
							window.alertErro('Este concurso não possui perguntas cadastradas no momento! , ou o usuário não possui o plano necessário!', '');   
						} 
					})
					.catch(error => { 
						console.log(error);
						window.alertProcessandoHide();
						
						if( error.status == 423 ){ 
							window.toastErro('Erro: ', error.data ); 
						} 
						else{
							window.toastErro('Não foi possivel achar as  perguntas'); 
						}
					});
				} 
			}  
		}

	


		proximaPergunta(){ 
			this.selected = '' ; 
			this.respondido = false ; 
			this.comentado = false ; 
			window.scrollTo(0, 0);  
			if(this.todas.length > 0 ){ 
				this.pergunta = this.todas.shift() ;
			}
			else{
				localStorage.removeItem('geral_treinamento_modulo_' + this.modulo.id + '_todas'); 
				this.concluido = false ;
				this.pergunta = null;
				this.getTodasPerguntas(); 
			} 
		}




		responder() {  
			
			if(!this.selected ){
				console.log('nao respondeu')
				return;
			}
			if( parseInt( this.selected ) === parseInt( this.pergunta.resposta_certa_id ) ){	
				this.respondidas.push({'pergunta':this.pergunta,'resposta':true,'resposta_id': this.selected}); 
				window.alertSucesso("Você Acertou!!" , ""); 

			}  
			else{
				this.respondidas.push({'pergunta':this.pergunta,'resposta':false,'resposta_id': this.selected});
				window.alertErro('Você Errou!', ''); 
			}

			localStorage.setItem('geral_treinamento_modulo_' + this.modulo.id + '_todas',JSON.stringify(  this.todas ) );				 
			localStorage.setItem('geral_treinamento_modulo_' + this.modulo.id + '_respondidas', JSON.stringify( this.respondidas ) );

			this.respondido = true ;
			this.comentario = '';


			const offset = $("#botao_proxima").offset();
			if(offset){
				$('html, body').animate({
					scrollTop:  offset.top  
				}, 0);
			}
 
			const data: any = {}; 
			data['pergunta_id'] = this.pergunta.id ; 
			data['selected'] = this.selected ; 
			data['disciplina_id'] = this.disciplina  ;
			data['tipo'] = this.pergunta.status  ;
			data['modulo_id'] = this.modulo.id  ;

			moduloGeralService.responderApiResposta( this.modulo , data)  
			.then(response => { 
				console.log('respondeu teste') ; 
			})
			.catch(error => { 
				console.log(error);
			});

		}


		
		pular(){
			window.alertConfimacao('Realmente deseja PULAR a Questão? ','' , 
                () => {  
					this.pularQuestao();  
				}
            ); 
		}



		pularQuestao() {  
			localStorage.setItem('geral_treinamento_modulo_' + this.modulo.id + '_todas',JSON.stringify(  this.todas ) ); 
			this.selected = '' ; 
			this.respondido = false ; 
			window.scrollTo(0, 0);   
			moduloGeralService.pularQuestao( this.modulo,  this.pergunta.id)  
			.then(response => { console.log('pulou') })
			.catch(error => { 
				console.log(error);
			}); 
			if(this.todas.length > 0 ){ 
				this.pergunta = this.todas.shift() ;
			}
			else{
				localStorage.removeItem('geral_treinamento_modulo_' + this.modulo.id + '_todas'); 
				this.concluido = false ;
				this.pergunta = null;
				this.getTodasPerguntas(); 
			}  
		}






		criarComentario(){ 
			this.comentado = true ;  
			const data: any = {};  
			data['texto'] = this.comentario ;
			data['pergunta_id'] = this.pergunta.id ; 
			data['status'] = 'Criada' ; 
			data['modulo_id'] = this.modulo.id ; 
			moduloGeralService.criarComentario(data)  
			.then(response => {   
				console.log(response);
			})
			.catch(error => {  
				console.log(error); 
			});  
			window.toastSucesso('Obrigado pelo comentário!'); 
			this.comentario = '';  
		}	

 


	}



  


