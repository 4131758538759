













    import { Component, Vue } from 'vue-property-decorator'; 
	import { profileUsuarioService }  from '@/service';

@Component({
  components: {  },
})
export default class TermoDeUso extends Vue {


    private termo: any = null; 

    created() {
        window.alertProcessando();
        
        profileUsuarioService.getTermoDeUso( ) 
        .then(response => {
            this.termo = response ;
            window.alertProcessandoHide();
        })
        .catch(error => {
            window.toastErro('Não foi possivel achar o termo de uso', error.data);
            window.alertProcessandoHide(); 
        })
        .finally( () => {
            window.alertProcessandoHide();
        });
 
	}
 

}
