/* eslint-disable @typescript-eslint/camelcase */ 

import { authHeader } from '..';  
import { geralModel } from './index.model'; 
import axios  from 'axios';  
import moment  from 'moment'; 
import * as CryptoJS from 'crypto-js'; 
const secret_key =  geralModel.getKeyCripto() ; 
const url = geralModel.getUrl() + '/concurso' ;  
import  store   from '@/store/index' 

function logout() {  
	store.dispatch('usuario/logout'); 
}

function getUrl(  ) { 
	return  url; 
}


function getTodosConcurso( modulo: any , api = false) {  
	return  new Promise((resolve, reject) => {  
		const conceitos = localStorage.getItem('global_model_concurso_todos_' + modulo );  
		if(conceitos && !api ){  
			const data_criacao = localStorage.getItem('data_global_model_concurso_todos_' + modulo  );   
			if( moment() < moment( data_criacao ).add(1, 'days') ){
				const bytes  = CryptoJS.AES.decrypt(conceitos, secret_key ); 
				const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
				resolve(   dados  ); 
				return;
			} 
		}   
		axios.get( url + '/modulo/' + modulo , {headers: authHeader() }  )
		.then(response => {    
			const encripted_conceitos = CryptoJS.AES.encrypt(JSON.stringify(response.data), secret_key ).toString(); 
			localStorage.setItem('global_model_concurso_todos_' + modulo , encripted_conceitos  );    
			localStorage.setItem('data_global_model_concurso_todos_'  + modulo, moment().format('YYYY-MM-DD')  ); 
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		})  
	});   
 
}

//TODO: verificar de qual lugar buscar
function getPerguntas( id: any ) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id  +'/perguntas'    , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
  

function getPerguntasCurso( modulo: any , id: any ) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/' + id + '/modulo/' + modulo +'/perguntas'    , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
  
function responderConcurso( modulo: any, data: any ) { 
	return  new Promise((resolve, reject) => {
		axios.post( modulo.urlresposta  + '/treinamento/responder'  , data  , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
  
   
  
export const concursoGeralService = { 
	getUrl, 
    getTodosConcurso,
    responderConcurso,
    getPerguntas,
    getPerguntasCurso
};