













 

	
	
	import { Watch, Prop, Component, Vue } from 'vue-property-decorator';


    @Component({
        components: {
             
        },
	}) 
	export default class Button extends Vue {

		@Prop( ) readonly url!: any
		@Prop( ) readonly form!: any
		@Prop( ) readonly metodo!: any
		@Prop( ) readonly retorno!: any


		get urlRetorno(){
			if(this.retorno){
				return  this.retorno;
			}
			return '/'; 
		}


		onSubmit() {
			// window.alertProcessando();
			// this.form.submit( this.metodo , this.url )
			// .then(response => { 
			// 	window.toastSucesso(response);  
			// 	window.alertProcessandoHide();
			// 	this.$router.push(  this.urlRetorno )
			// })
			// .catch(errors => { 
			// 	window.alertProcessandoHide();
			// 	console.log(errors);
			// });
		} 


	}


	 

