
import  { apiModel } from '../api.url'; 

const url =  apiModel.getApi( ) + '/seguranca'  ;
 

function getUrl(  ) { 
	return  url; 
}
 

 
export const segurancaModel = { 
	getUrl,  
};