
// window._ = require('lodash');

// const _ = require('lodash');

// window._ = lodash ;
window._ = require('lodash');

window.Popper = require('popper.js').default; 

window.$ = window.jQuery = require('jquery'); 

 


require('bootstrap');