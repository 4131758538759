



























































  
    import { moduloGeralService } from '@/service';  
    import { Watch, Component, Vue } from 'vue-property-decorator';
	import moment  from 'moment'; 
    import { namespace } from 'vuex-class' 
    import FormTermoUso from './_termo.vue' 
    const ambiente = namespace('ambiente')
      
    @Component({
        components: {
	        FormTermoUso,
        },
    })
    export default class Modulos extends Vue { 


        @ambiente.State
		public modulo!: any

		public modulos: any = null;

        @ambiente.Action
        public alterarModulo!: ( modulo: any ) => void
         
        @ambiente.Action
        public resetModulo!: (  ) => void
 
        public nome: any = null;
        public modulosFiltrados: any = null;

        created() { 
            this.getModulos(false); 
        }
     
		@Watch('nome')
		filtroAlterado(newVal: any) {
            this.filtrar();
		}
        
		async filtrar() {
            if(!this.nome || this.nome == '' || this.nome == ' ' ){
                this.modulosFiltrados = this.modulos;
            }
            else{
                this.modulosFiltrados = this.modulos.filter( ( mod: any) => {
                    if (mod.nome.toLowerCase().includes(  this.nome.toLowerCase() ) ) {
                        return true;
                    }
                }) ;
            } 
		} 
		 
        public getModulos( api: boolean ){
            window.alertProcessando();  
            moduloGeralService.getAll( api )
                .then(response => {
                    this.modulos = response;
                    this.filtrar();
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar a Modulo', error.data);
                    window.alertProcessandoHide(); 
                }); 
        }
  
        atualizarModulos(){
            this.resetModulo();
            this.getModulos(true);
        }
 
		databr(value: any): string {
			return moment(value).format("DD/MM/YYYY");
		}
    }
