















	/* eslint-disable @typescript-eslint/camelcase */  

	import { Prop, Component, Vue } from 'vue-property-decorator'; 

	  
 

	@Component({
		components: {  
        },
	})
	export default class FormTermoUsoModulo extends Vue {
 
	 
		@Prop( ) readonly modulo!: any

		private add_plano_id = 'modal-seguranca-usuario-adicionar-plano-' + this.modulo.id; 
		private termo: any =  ''   ; 

		 
		 
	}

