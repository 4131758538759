




















    import { Watch, Prop, Component, Vue } from 'vue-property-decorator';


    @Component({
        components: {
             
        },
	}) 
	export default class Accordion extends Vue {

        @Prop({default: 'accordion'} ) readonly identificador!: string
        @Prop( ) readonly list!: any

    }

 

