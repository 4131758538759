




















 
	import { Watch, Prop, Component, Vue } from 'vue-property-decorator';
 
    @Component({
        components: { 
        },
	}) 
	export default class Header extends Vue { 
		@Prop( ) readonly texto!: any 
	}
 

