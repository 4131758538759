














 

  import Header from '@/components/layout/Header.vue'
  import BarraLateral from '@/components/layout/BarraLateral.vue'
  import Footer from '@/components/layout/Footer.vue'

  import { Component, Vue   } from 'vue-property-decorator';

  import { namespace } from 'vuex-class' 
  const ambiente = namespace('ambiente')

  @Component({
    components: {
			Header,
      BarraLateral,
      Footer,
		},
  })
  export default class App extends Vue { 
    @ambiente.State
		public tema!: any 

  }

  
