




































/* eslint-disable @typescript-eslint/camelcase */ 
	
	import { arquivoService }  from '@/service';

    import { namespace } from 'vuex-class'

	import { Component, Vue } from 'vue-property-decorator';
      
    const usuario = namespace('usuario')

    @Component({
        components: { },
    })
    export default class Principal extends Vue { 

		@usuario.State
		public user!: any


        private url_retorno: any = '/v2/geral/arquivo' ;
        private model: any = null ;
 
		created() { 
			window.alertProcessando();
			arquivoService.getModel( this.$route.params.id )
			.then(response => {
				this.model = response ;
				window.alertProcessandoHide();
			})
			.catch(error => {
				window.alertProcessandoHide();
				 
				window.toastErro('Não foi possivel achar texto');
			}); 
		}


	}
 

