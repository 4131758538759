













	
	import { moduloGeralService  }  from '@/service';
 
	import { Prop , Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class SemanaClassificacao extends Vue { 

		@Prop( ) readonly modulo!: any
        
        private config = {
			dom: '<"top"r>t<"bottom"i><"clear">', 
			lengthMenu : [ [ -1],   [  "Todos"]  ],
			ajax: { 
				url: + '/classificacao/datatable'
			},
			columns: [
				{ data: 'rownum', name: 'rownum', orderable: false, searchable: false }, 
				{ data: 'user_id', name: 'user_id', orderable: false, searchable: false }, 
				{ data: 'acertos', name: 'acertos', orderable: false, searchable: false },  
				{ data: 'ultima_tentativa', name: 'ultima_tentativa' , orderable: false, searchable: false},   
				{ data: 'total', name: 'total' , orderable: false, searchable: false},  
				{ data: 'rendimento', name: 'rendimento' , orderable: false, searchable: false},  
			],
		};
 
		created() {
            if( this.modulo){
				this.config.ajax.url =  moduloGeralService.getUrl() + '/' + this.modulo.id  + '/simulado/classificacao/datatable';
			}
			 
        }


	}
	
 

