



























































/* eslint-disable @typescript-eslint/camelcase */ 

    import { relatorioGeralService } from '@/service';
     
    import GraficoRendimento from '../chart/_rendimento.vue'; 
    import GraficoDisciplina from '../chart/_disciplina.vue' ;

    import { namespace } from 'vuex-class'
    // const usuario = namespace('usuario') 
    const ambiente = namespace('ambiente')

    import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { 
            GraficoRendimento, 
            GraficoDisciplina, 
         },
    })
    export default class GraficoIndex extends Vue { 

         
        @ambiente.State
		public modulo!: any


        private tentativas_disciplinas: any = null; 
        private tentativas_disciplinas_geral: any = null; 
        private disciplinas: any = null; 
        private disciplinas_geral: any = null; 
        
    //     @usuario.State
    //     public user!: any


    //     @usuario.State
    //     public perfis!: any

    //     get admin(): boolean {
    //       if (this.perfis) {
    //           return (
    //               this.perfis.filter( ( perfil: any) => {
    //                   if (perfil == "Admin") {
    //                       return true;
    //                   }
    //               }).length > 0
    //           );
    //       }
    //       return false;
    //   }



        created() {  
            if(!this.modulo){
				this.$router.push('/v2/geral/ambiente');
			}  
            else{
                this.buscarTentativasDisciplinaMes();  
                this.buscarTentativasGeralDisciplinaMes();  
            } 
        }

        buscarTentativasDisciplinaMes() { 
            relatorioGeralService.buscarTentativasDisciplinaMes( this.modulo )   
			.then(response => { 
				this.tentativas_disciplinas = response;
				this.pegaDisciplina() ;
			})
			.catch(error => { 
                window.toastErro('Erro: ', error.data);
             });  
        }



        buscarTentativasGeralDisciplinaMes() { 
            window.alertProcessando();
            relatorioGeralService.buscarTentativasGeralDisciplinaMes( this.modulo )   
			.then(response => {
				window.alertProcessandoHide();
				this.tentativas_disciplinas_geral = response;
				this.pegaDisciplinaGeral() ;
			})
			.catch(error => { 
				window.alertProcessandoHide(); 
			});  
        }



        pegaDisciplina() { 
            const result = [];
            const map = new Map();  
                for ( const  item of  this.tentativas_disciplinas) { 
                    if(!map.has(item.nome)){
                        map.set(item.nome, true);     
                        result.push(
                            item.nome
                        );
                    }
                }
            
            this.disciplinas = result;
        }


        pegaDisciplinaGeral() { 
            const result = [];
            const map = new Map();  
                for ( const  item of  this.tentativas_disciplinas_geral) { 
                    if(!map.has(item.nome)){
                        map.set(item.nome, true);     
                        result.push(
                            item.nome
                        );
                    }
                }
            
            this.disciplinas_geral = result;
        }
            
        montarDisciplina(nome: any) { 
            return this.tentativas_disciplinas.filter(function(el: any) {
                return el.nome === nome;
            })
        }
        
        montarDisciplinaGeral(nome: any) { 
            return this.tentativas_disciplinas_geral.filter(function(el: any) {
                return el.nome === nome;
            })
        }
            
    }




	 
