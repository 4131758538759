



















































	/* eslint-disable @typescript-eslint/camelcase */
	import { relatorioGeralService }  from '@/service';
 
    import { Component, Vue } from 'vue-property-decorator';
     
    import { namespace } from 'vuex-class' 
    const ambiente = namespace('ambiente')
      
    @Component({
        components: { },
    })
    export default class RelatorioIndex extends Vue { 
 

        @ambiente.State
		public modulo!: any



        private relatorio_dia_hora: any = null; 
        

        created() { 
            if(!this.modulo){
				this.$router.push('/v2/geral/ambiente');
			}  
            else{
                this.buscarRelatorioDiaHora();  
            } 
        }
  

        buscarRelatorioDiaHora() {  
            window.alertProcessando(); 
            relatorioGeralService.buscarTentativasUsuarioDiaHora( this.modulo)   
			.then(response => { 
                this.relatorio_dia_hora = response;
                window.alertProcessandoHide();  
			})
			.catch(() => {  
                window.alertProcessandoHide(); 
            });  
        }
 
        get totalRespostaHoraDia(): number {
            let total = 0; 
            for (let i = 0 ; i <= this.relatorio_dia_hora.length - 1; i++) { 
				total +=  parseInt(this.relatorio_dia_hora[i].count);
            }  
            return total;
        }
 

    }

 

