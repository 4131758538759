

















































































































































 
	
	import { Component, Vue } from 'vue-property-decorator'; 
	import { relatorioGeralService }  from '@/service';

    import { namespace } from 'vuex-class'
    const usuario = namespace('usuario')
    const ambiente = namespace('ambiente')


    @Component({
    components: {  },
    })
    export default class Privacidae extends Vue {


        @ambiente.State
		public modulo!: any

        @usuario.State
        public user!: any

        @usuario.State
        public token!: any


        private privacidade: any = null; 

        private url = relatorioGeralService.getUrl(); 
         
        created() { 
            if(!this.modulo){
				this.$router.push('/v2/geral/geral/modulos');
			}  
        }


        get urlConceitos(): string {
            return  this.url +  '/modulo/' +  this.modulo.id + '/tag?token=' + this.token; 
        }


        get urlDisciplina(): string {
            return   this.url +  '/modulo/' +  this.modulo.id + '/rendimento/disciplina?token=' + this.token;  
        }


        



    }

	 

