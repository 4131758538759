

























































































































/* eslint-disable @typescript-eslint/camelcase */ 
	
	import {  concursoGeralService  }  from '@/service';
    import $ from "jquery"  
 
	import _concurso from './_concurso.vue'; 
	import _Parciais from './_Parciais.vue';
	import _Info from './_Info.vue';
	import _Relatorio from './_Relatorio.vue';
	   

	import { Component, Vue , Watch} from 'vue-property-decorator';
	

	import { namespace } from 'vuex-class' 
    const ambiente = namespace('ambiente')


    @Component({
        components: { 
			'form-concurso': _concurso,   
            'parciais': _Parciais, 
            'info': _Info, 
			'relatorio': _Relatorio,   
		},
    })
    export default class ConcursoPergunta extends Vue { 

		@ambiente.State
		public modulo!: any

		@ambiente.State
		public tema!: any 
 
        private selected: any = null;
        private pergunta: any = null;
        private todas: any = [];
        private respondidas: any = [];
        private respondido = false;
        private concluido = false; 
        private concurso: any = null;
        private concursos: any = null;   
        private tema_local = false;


  
		@ambiente.Action
        public alterarTema!: ( tema: boolean ) => void

		@Watch('tema_local')
		temaAlterado(newVal: any) {
			this.alterarTema(this.tema_local);
		}
		
		created() { 
			if(!this.modulo){
				this.$router.push('/v2/geral/geral/modulos');
			} 
			else{
				this.tema_local = this.tema;
				this.getTodosConcursos();   
				localStorage.removeItem('concurso_todas' );
			} 
		}
				 
		
		mounted() { 
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper');

			if(header){ 
				header.hidden = true ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer');
			
			if(header){ 
				header.hidden = false ; 
			}

			if(footer){ 
				footer.hidden = false ; 
			}

			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
		}


		 
		get concurso_atual(): string {
			if(this.concursos){
				for (let i = this.concursos.length - 1; i >= 0; i--) {
					if(this.concursos[i].id == this.concurso ){
						return this.concursos[i].nome;
					}					
				} 
			} 
			return '';
		}



		mudouConcurso(evento: any) {	 
			this.concurso = evento ;	
			this.respondidas =[];
			this.concluido = false ; 

			localStorage.removeItem('concurso_respondidas' );
			localStorage.removeItem('concurso_todas' ); 
			this.getTodasPerguntas();   
		}



		 
		getTodosConcursos() {	 
			window.alertProcessando();  
			concursoGeralService.getTodosConcurso(this.modulo.id , true )	 
			.then(response => {
				this.concursos = response ;  
				window.alertProcessandoHide();
			})
			.catch(error => {
				// window.toastErro('Não foi possivel achar as concursos anteriores' ,  error.data);  
				window.alertErro('',  error.data.message, 'center', null); 
				window.alertProcessandoHide();
				this.$router.push('/'); 
			}); 
		}


		 
 

		 


		setRespondidas( valores: any ) {
			this.respondidas =[];  
			for (let i = 0 ; i <= valores.length - 1 ; i++) {  
				this.respondidas.push({ 'pergunta' : valores[i].pergunta,'resposta':valores[i].acerto ,'resposta_id':valores[i].resposta_id , 'tags' : valores[i].tags });  
			} 
			localStorage.setItem('concurso_respondidas', JSON.stringify( this.respondidas ) ); 
		}




		//BUSCA TODAS AS PERGUNTA DA DISCIPLINA
		getTodasPerguntas() {	
			this.pergunta = null ;	
			const concurso_todas =  localStorage.getItem('concurso_todas');
			if (concurso_todas) {
				this.todas = JSON.parse( concurso_todas ) ; 
				this.proximaPergunta(); 
			}
			else if(this.concurso){ 

				window.alertProcessando();  
				concursoGeralService.getPerguntasCurso( this.modulo.id , this.concurso) 
				.then((response: any) => {
					this.todas = response.perguntas ; 
					if(this.todas && this.todas.length > 0 ){
						localStorage.setItem('concurso_todas', JSON.stringify( response.perguntas ) ); 
						this.setRespondidas( response.respostas ); 
						this.proximaPergunta(); 
						window.alertProcessandoHide();
					}
					else{
						window.alertProcessandoHide();
						window.alertErro('Este concurso não possui perguntas cadastradas no momento!', '');  // TODO: TELA FICA TREMENDO COM ESTA MENSAGEM 
					}  
				})
				.catch(error => { 
					console.log(error);
					window.alertProcessandoHide();
					 
					if( error.status == 403 ){ 
						window.toastErro('Erro: ', error.data.message ); 
					} 
					else{
						window.toastErro('Não foi possivel achar as  perguntas'); 
					}
				});
			} 
			
		}


		proximaPergunta(){ 
			this.selected = '' ; 
			this.respondido = false ;  
			window.scrollTo(0, 0); 
			
			if(this.todas && this.todas.length > 0 ){
				this.pergunta = this.todas.shift() ; 
			}
			else{
				localStorage.removeItem('concurso_respondidas' ); 
				localStorage.removeItem('concurso_todas' ); 
				this.concluido = false ; 
				this.getTodasPerguntas(); 
			} 
		}
		


		responder() {  
			if(!this.selected ){
				return;
			}
			
			if( parseInt( this.selected ) === parseInt( this.pergunta.resposta_certa_id ) ){	
				this.respondidas.push({'pergunta':this.pergunta,'resposta':true,'resposta_id': this.selected   });  
				window.alertSucesso("Você Acertou!!" , "");  
			}  
			else{
				this.respondidas.push({'pergunta':this.pergunta,'resposta':false,'resposta_id': this.selected    }); 
				window.alertErro('Você Errou!', '');  
			}

			localStorage.setItem('concurso_todas',JSON.stringify(  this.todas ) );				 
			localStorage.setItem('concurso_respondidas', JSON.stringify( this.respondidas ) );

			if(this.todas && this.todas.length === 0 ){
				window.toastSucesso('concurso Finalizado!!' ); 
			}

			this.respondido = true ; 


			const offset = $("#botao_proxima").offset();
			if(offset){
				$('html, body').animate({
					scrollTop:  offset.top  
				}, 0);
			}


			 
			const data: any = {}; 
			data['pergunta_id'] = this.pergunta.id ; 
			data['selected'] = this.selected ; 
			data['referencia_id'] = this.concurso  ;
			data['disciplina_id'] = this.pergunta.disciplina_id  ;
			data['desafio_id'] = this.pergunta.desafio_id  ;
			data['tipo'] = 'CONCURSO' ;
			data['modulo_id'] = this.modulo.id  ; 

			concursoGeralService.responderConcurso( this.modulo, data)  
			.then(response => { 
				console.log('respondeu')
			}) ;

		}


 


	}

  
	
