












	
	import { Watch, Prop, Component, Vue } from 'vue-property-decorator';


    @Component({
        components: {
             
        },
	}) 
	export default class Card extends Vue {

		@Prop( ) readonly color!: any
		@Prop( ) readonly config!: any 
	}


 

