






















































/* eslint-disable @typescript-eslint/camelcase */ 
	
	import {   relatorioGeralService  }  from '@/service';

	import FormDisciplina from './_disciplina.vue'; 
   
	import { Component, Vue , Watch } from 'vue-property-decorator';
      
	import { namespace } from 'vuex-class' 
    const ambiente = namespace('ambiente')

    @Component({
        components: { 
			'form-disciplina': FormDisciplina,
		},
    })
    export default class ComentarioIndex extends Vue { 

        @ambiente.State
		public modulo!: any

		@ambiente.State
		public tema!: any 

        private assunto: any =null ;

        private disciplina_atual: any = {} ;
		private conceitos: any = null ;
		

		private tema_local = false;



		
		@ambiente.Action
        public alterarTema!: ( tema: boolean ) => void


		@Watch('tema_local')
		temaAlterado(newVal: any) {
			this.alterarTema(this.tema_local);
		}


		created() { 
            if(!this.modulo){
				this.$router.push('/v2/geral/geral/modulos');
			}  
			else{
				this.tema_local = this.tema;
			}
			
        }


		mounted() {
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const footer = document.getElementById('footer');
			const contentwrapper = document.getElementById('contentwrapper');

			if(header){ 
				header.hidden = true ; 
			}
			if(footer){ 
				footer.hidden = true ; 
			}
			if(sidebar){
				sidebar.hidden = true ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = "0px" ;   
			} 
		}


		destroyed(){  
			const header = document.getElementById('header');
			const sidebar = document.getElementById('sidebar');
			const contentwrapper = document.getElementById('contentwrapper');
			const footer = document.getElementById('footer');
			
			if(header){ 
				header.hidden = false ; 
			}

			if(footer){ 
				footer.hidden = false ; 
			}

			if(sidebar){
				sidebar.hidden = false ;
			}
			if(contentwrapper){
				contentwrapper.style.marginLeft = null ;   
			}  
		}


		 


		mudouDisciplina(evento: any) {	  
			this.disciplina_atual = evento.disciplina ;	
			this.assunto = evento.assunto ;	 
			if(this.assunto){
				this.getConceitosPorAssunto() ;	 
			}
			else{
				this.getConceitos() ;	
			}
		}
		

		getConceitos() { 
			window.alertProcessando();   
			relatorioGeralService.getConceitos( this.modulo , this.disciplina_atual.id )	 
			.then(response => {
				this.conceitos = response ;  
				window.alertProcessandoHide();
			}) 
			.catch(error => {
				window.toastErro('Não foi possivel achar as conceitos'); 
				window.alertProcessandoHide(); 
			});	 
		}

		getConceitosPorAssunto() { 
			window.alertProcessando();   
			relatorioGeralService.getConceitosPorAssunto( this.modulo.id , this.disciplina_atual.id , this.assunto)	 
			.then(response => {
				this.conceitos = response ;  
				window.alertProcessandoHide();
			}) 
			.catch(error => {
				window.toastErro('Não foi possivel achar as conceitos'); 
				window.alertProcessandoHide(); 
			});	 
		}
			


	}
	



 


