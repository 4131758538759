


















	
	import { moduloGeralService  }  from '@/service';
	import $ from "jquery"   
	import { Component, Vue } from 'vue-property-decorator';
      

	import { namespace } from 'vuex-class' 
    const ambiente = namespace('ambiente')


    @Component({
        components: { },
    })
    export default class PriSemanalHistoricoIndexncipal extends Vue { 


		@ambiente.State
		public modulo!: any


		created() { 
            if(!this.modulo){
				this.$router.push('/v2/geral/geral/modulos');
			}  
			else{
				this.config.ajax.url = moduloGeralService.getUrl() + '/'+ this.modulo.id  + '/simulado/historico/datatable';
			}
        }



        private config = { 
			order: [[ 2, "desc" ]],
			ajax: { 
				url:  moduloGeralService.getUrl() + '/simulado/historico/datatable', 
			},
			columns: [
				{ data: 'id', name: 'id' , class: 'text-center' },
				{ data: 'nome', name: 'nome' }, 
				{ data: 'created_at', name: 'created_at'  , searchable: false }, 
				{ data: 'action', name: 'action', orderable: false, searchable: false, class: 'text-center'}
			],
		};


		public tableatualizada( ) {  
            $('[btn-visualizar]').click(  (btn) => {  
                const id =   $(btn.currentTarget).data('id'); 
                this.$router.push('/v2/geral/simulado/historico/' + id  );
            }); 
		}

 
	}
	 
