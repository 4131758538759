/* eslint-disable @typescript-eslint/camelcase */ 

import { authHeader } from '..';  
import { geralModel } from './index.model'; 
import axios  from 'axios'; 
    
const url = geralModel.getUrl() + '/profile' ; 
const urlSite = geralModel.getSiteApi()   ; 

import  store   from '@/store/index' 

function logout() {  
	store.dispatch('usuario/logout'); 
}

function getUrl(  ) { 
	return  url; 
}
 
function getConfiguracao( item: any ) { 
	return  new Promise((resolve, reject) => {
		axios.post( urlSite  + "/api/configuracao" , {item: item} , { }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}
  
function getPrivacidade() { 
	return  new Promise((resolve, reject) => {
		axios.get( urlSite  + "/api/privacidade" , { }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}
  
function getTermoDeUso() { 
	return  new Promise((resolve, reject) => {
		axios.get( urlSite  + "/api/termo_de_uso" , { }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			reject(error.response);
		}) 
	}); 
}
 
function alterarSenha( dados: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.post( url + '/update/senha' , dados, {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
  
function getMeusPlanos(   ) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/meus/planos'  , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
  
function PossuiPlano(  id: any  ) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/meus/planos/modulo/' + id   , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
function gerarQRCode(  id: any , meses: any ) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/meus/planos/modulo/' + id + '/meses/' +  meses , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
function getPagamento(  id: any   ) { 
	return  new Promise((resolve, reject) => {
		axios.get( url + '/meus/planos/pagamento/' + id   , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
export const profileUsuarioService = { 
	getUrl,
	alterarSenha,
	getMeusPlanos,
	PossuiPlano ,
	gerarQRCode,
	getPagamento,
	getConfiguracao,
	getPrivacidade,
	getTermoDeUso,
};