


















 
	import {Prop, Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: { },
    })
    export default class SemanalParciais extends Vue { 


		@Prop( ) readonly respondidas!: any
		@Prop( ) readonly tema!: any 


		get certas(): number {
			let total= [];
			if(this.respondidas){
				total = this.respondidas.filter(function (number: any) {
					return number.resposta
				});	
			} 	 	 
			return total.length;
		}


		get erradas(): number {
			let total= [];
			if(this.respondidas){
				total = this.respondidas.filter(function (number: any) {
					return ! number.resposta
				});
			}				 	 
			return total.length;
		}



		get rendimento(): string {
			const total = this.certas + this.erradas;
			let valor = 0 ; 
			if(total>0){
				valor =  this.certas / total * 100  ; 
			}			 
			return valor.toFixed(1);
		}

	}

 

