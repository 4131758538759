










































































/* eslint-disable @typescript-eslint/camelcase */ 

    import { relatorioGeralService } from '@/service';
    
    import GraficoTentativa from '../chart/_tentativas.vue';
    import GraficoRendimento from '../chart/_rendimento.vue';
    import GraficoErros from '../chart/_erros.vue' ;
     

    import { namespace } from 'vuex-class'
    // const usuario = namespace('usuario')
    const ambiente = namespace('ambiente')


    import { Component, Vue } from 'vue-property-decorator';
      
    @Component({
        components: {
            GraficoTentativa,
            GraficoRendimento,  
            GraficoErros,
         },
    })
    export default class GraficoGeralIndex extends Vue { 


        @ambiente.State
		public modulo!: any


        private datacollection: any = null; 
        private datacollectionGeral: any = null; 
          
        // @usuario.State
        // public user!: any


        // @usuario.State
        // public perfis!: any
 
        // get admin(): boolean {
        //   if (this.perfis) {
        //       return (
        //           this.perfis.filter( ( perfil: any) => {
        //               if (perfil == "Admin") {
        //                   return true;
        //               }
        //           }).length > 0
        //       );
        //   }
        //   return false;
        // }



        created() { 

            if(!this.modulo){
				this.$router.push('/v2/geral/ambiente');
			}  
            else{
                this.buscarTentativasMes();  
                this.buscarTentativasGeralMes();  
            } 
        }




        buscarTentativasMes() { 
            relatorioGeralService.buscarTentativasMes(  this.modulo )   
			.then(response => { 
				this.datacollection = response;
			})
			.catch(error => {
				window.toastErro('Erro: ', error.data); 
            });  
        }




        buscarTentativasGeralMes() { 
            window.alertProcessando(); 
            relatorioGeralService.buscarTentativasGeralMes( this.modulo )   
			.then(response => {
				window.alertProcessandoHide();
				this.datacollectionGeral = response;
			})
			.catch(error => { 
				window.alertProcessandoHide(); 
            });  
        }


        
            
    }




	 
