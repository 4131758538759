/* eslint-disable @typescript-eslint/camelcase */ 

import { authHeader } from '..';  
import { geralModel } from './index.model'; 
import axios  from 'axios';  
import moment  from 'moment'; 
import * as CryptoJS from 'crypto-js'; 
const secret_key =  geralModel.getKeyCripto() ;
 
const url = geralModel.getUrl() + '/relatorio' ; 

import  store   from '@/store/index' 

function logout() {  
	store.dispatch('usuario/logout'); 
}

function getUrl(  ) { 
	return  url; 
}
 
function buscarTentativasUsuarioDiaSemana(  modulo: any) { 
	return  new Promise((resolve, reject) => {  
		axios.get(modulo.url + 'api/v1/geral/relatorio/modulo/' + modulo.id + "/dia/semana" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
function buscarTentativasUsuarioDiaHora(  modulo: any) { 
	return  new Promise((resolve, reject) => {  
		axios.get( modulo.url + 'api/v1/geral/relatorio/modulo/' + modulo.id + "/dia/hora" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
function buscarTentativasUsuarioDisciplina(   modulo: any) { 
	return  new Promise((resolve, reject) => {  
		axios.get( modulo.url + 'api/v1/geral/relatorio/modulo/' + modulo.id + "/disciplina" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
function buscarTentativasMes(   modulo: any) { 
	return  new Promise((resolve, reject) => {  
		axios.get(modulo.url + 'api/v1/geral/relatorio/modulo/' + modulo.id + "/tentativas/mes/270" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
  
function buscarTentativasGeralMes(  modulo: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.get( modulo.url + 'api/v1/geral/relatorio/modulo/' + modulo.id + "/tentativas/mes/geral/270" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
function buscarTentativasDisciplinaMes(   modulo: any ) { 
	return  new Promise((resolve, reject) => {  
		axios.get( modulo.url + 'api/v1/geral/relatorio/modulo/' + modulo.id + "/tentativas/disciplina/mes/270" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
function buscarTentativasGeralDisciplinaMes( modulo: any) { 
	return  new Promise((resolve, reject) => {  
		axios.get( modulo.url + 'api/v1/geral/relatorio/modulo/' + modulo.id + "/tentativas/disciplina/mes/geral/270" , {headers: authHeader() }  )
		.then(response => {    
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		}) 
	}); 
}
 
function getConceitos(  modulo: any, disciplina_id: any ) {  
	const id = modulo.id;
	return  new Promise((resolve, reject) => {  
		const conceitos = localStorage.getItem('global_model_conceitos_todos_modulo_' + id + '_disciplina_' +  disciplina_id );  
		if(conceitos){  
			const data_criacao = localStorage.getItem('data_global_model_conceitos_todos_modulo_' + id + '_disciplina_' +  disciplina_id);   
			if( moment() < moment( data_criacao ).add(1, 'days') ){
				const bytes  = CryptoJS.AES.decrypt(conceitos, secret_key ); 
				const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
				resolve(   dados  ); 
				return;
			} 
		}  
		axios.get( modulo.url + 'api/v1/geral/relatorio/modulo/' + id + '/disciplina/' + disciplina_id + '/conceitos/principais'  , {headers: authHeader() }  )
		.then(response => {     
			const encripted_conceitos = CryptoJS.AES.encrypt(JSON.stringify(response.data), secret_key ).toString(); 
			localStorage.setItem('global_model_conceitos_todos_modulo_' + id + '_disciplina_' +  disciplina_id , encripted_conceitos  );    
			localStorage.setItem('data_global_model_conceitos_todos_modulo_' + id + '_disciplina_' +  disciplina_id , moment().format('YYYY-MM-DD')  ); 
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		})  
	});  
}
  
function getConceitosPorAssunto(  id: any, disciplina_id: any , assunto: any ) {  
	return  new Promise((resolve, reject) => {  
		const conceitos = localStorage.getItem('global_model_conceitos_todos_modulo_' + id + '_disciplina_' +  disciplina_id + '_assunto_' +  assunto);  
		if(conceitos){  
			const data_criacao = localStorage.getItem('data_global_model_conceitos_todos_modulo_' + id + '_disciplina_' +  disciplina_id + '_assunto_' +  assunto);   
			if( moment() < moment( data_criacao ).add(1, 'days') ){
				const bytes  = CryptoJS.AES.decrypt(conceitos, secret_key ); 
				const dados = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 
				resolve(   dados  ); 
				return;
			} 
		}  
		axios.get( url  + '/modulo/' + id + '/disciplina/' + disciplina_id + '/assunto/' + assunto + '/conceitos/principais'  , {headers: authHeader() }  )
		.then(response => {     
			const encripted_conceitos = CryptoJS.AES.encrypt(JSON.stringify(response.data), secret_key ).toString(); 
			localStorage.setItem('global_model_conceitos_todos_modulo_' + id + '_disciplina_' +  disciplina_id + '_assunto_' +  assunto , encripted_conceitos  );    
			localStorage.setItem('data_global_model_conceitos_todos_modulo_' + id + '_disciplina_' +  disciplina_id + '_assunto_' +  assunto, moment().format('YYYY-MM-DD')  ); 
			resolve( response.data);  
		})
		.catch(error => {  
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response);
		})  
	});  
}
  


export const relatorioGeralService = { 
	getUrl, 
	buscarTentativasUsuarioDiaSemana,
	buscarTentativasUsuarioDiaHora,
	buscarTentativasUsuarioDisciplina,
	buscarTentativasMes,
	buscarTentativasGeralMes,
	buscarTentativasDisciplinaMes,
	buscarTentativasGeralDisciplinaMes,
	getConceitos,
	getConceitosPorAssunto,
};