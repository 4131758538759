



















 
	
	import { Component, Vue } from 'vue-property-decorator'; 
	import { profileUsuarioService }  from '@/service';

    @Component({
    components: {  },
    })
    export default class Privacidae extends Vue {

        private privacidade: any = null; 

        created() {
            window.alertProcessando();
            
            profileUsuarioService.getPrivacidade( ) 
            .then(response => {
                this.privacidade = response ;
                window.alertProcessandoHide();
            })
            .catch(error => {
                window.toastErro('Não foi possivel achar a politica de privacidade', error.data);
                window.alertProcessandoHide(); 
            })
            .finally( () => {
                window.alertProcessandoHide();
            });

                


        }

        mounted() { 
            const contentwrapper = document.getElementById('contentwrapper');  
            if(contentwrapper){
                contentwrapper.hidden = true ; 
            } 
        }


        destroyed(){   
            const contentwrapper = document.getElementById('contentwrapper');  
            if(contentwrapper){
                contentwrapper.hidden = false ; 
            }  
        }


    
    }

	 

