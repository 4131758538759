






























































































































































































  
    import { Component, Vue } from 'vue-property-decorator'; 
	  import { profileUsuarioService }  from '@/service'; 

    @Component({
        components: { },
    })
    export default class Principal extends Vue { 

      private valordoacao  = null; 
 
      created() {  
        profileUsuarioService.getConfiguracao( 'valor_doacao' ) 
        .then(response => {
            this.valordoacao = response ;
            window.alertProcessandoHide();
        })
        .catch(error => { 
            window.alertProcessandoHide(); 
            print(error)
        })
        .finally( () => {
            window.alertProcessandoHide();
        }); 
      }

 
  
    }
