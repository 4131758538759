


















	 
	import { Component, Vue } from 'vue-property-decorator';
    import { moduloGeralService } from '@/service';  
	import { namespace } from 'vuex-class' 
    const ambiente = namespace('ambiente')

	

    @Component({
        components: { },
    })
    export default class SemanalIndex extends Vue { 
		

		@ambiente.State
		public modulo!: any

		private model: any = null;


		created() { 
			
            if(!this.modulo){
				this.$router.push('/v2/geral/geral/modulos');
			}  
			else{
				if(!this.modulo.has_simulado){
					this.$router.push('/');
				}  
			}
			window.alertProcessando();
			moduloGeralService.getModulo(this.modulo.id)
                .then(response => {
                    this.model = response;
                    window.alertProcessandoHide();
                })
                .catch(error => {
                    window.toastErro('Não foi possivel achar a Modulo', error.data);
                    window.alertProcessandoHide(); 
                });
        }



	}
	 
