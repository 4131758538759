













































































 
	
	/* eslint-disable @typescript-eslint/camelcase */ 
	import {  cadastroService } from '@/service'; 
	 
	import FormTermoUso from './_modal_termo.vue'  

	import {  Component, Vue } from 'vue-property-decorator'; 
	  
	@Component({
		components: { 
			'termmo-uso': FormTermoUso,
		 },
	 })
    export default class  Cadastro extends Vue {
		
		
		private termo: any = false;
		private nome: any= null;
		private email: any= null;
		private id: any= null; 
		private apelido: any= null; 
		private password: any= null; 
		private passwordConfirm: any= null;  


		cadastrar() {
			if(this.nome && this.email && this.id && this.apelido && this.password && this.passwordConfirm   ){
				window.alertProcessando();

				const dados = {
					nome :this.nome,
					email :this.email,  
					id :this.id,  
					apelido :this.apelido,  
					password :this.password,  
					passwordConfirm :this.passwordConfirm,   
				};  
				
				 
				cadastroService.cadastrar(   dados )
				.then(response => { 
					window.toastSucesso('Cadastro realizado com Sucesso!!'); 
					window.alertProcessandoHide();
					this.$router.push('/login');
				})
				.catch(error => {  
					window.alertProcessandoHide();
					window.toastErro('Não foi possivel realizar o cadastro' , error.message );  
					console.log(error);
				});  
			}
			
 
 
		}
		
		mounted() { 
			const contentwrapper = document.getElementById('contentwrapper');  
			if(contentwrapper){
				contentwrapper.hidden = true ; 
			} 
		}


		destroyed(){   
			const contentwrapper = document.getElementById('contentwrapper');  
			if(contentwrapper){
				contentwrapper.hidden = false ; 
			}  
		}


	}

 
