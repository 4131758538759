




































































 
 
 import FormTermoUso from '../_modal_termo.vue'

import { Component, Vue } from 'vue-property-decorator'; 
import { namespace } from 'vuex-class';

const usuario = namespace('usuario');
// import router from '@/router/index';


@Component({
  components: { 
	FormTermoUso,
  },
})
export default class Login extends Vue {


	@usuario.State
	public mensagem!: string;

	@usuario.State
	public status!: any


	private username = "";
	private password = ""; 
	public logado	 = false; 
	public termo	 = ''; 

	created() {
		if(this.loggingIn){
			this.$router.push('/');
		}
		// else{
		// 	this.logout( );
		// } 
    }
 

	get loggingIn(): boolean {
		if (this.status) {
			return  this.status.loggedIn ;
		}
		return false;
	}

	@usuario.Action
	public logout!: ( ) => void
	  

	@usuario.Action
	public login!: ( dados: any  ) => Promise<any>

	public chamadalogin(): void {   
		( window as any).$('body').addClass('loading');
		if ( this.username && this.password) {  
			this.login({ username : this.username , password : this.password }  )
			.then(() => { 
				( window as any).$('body').removeClass('loading');
				this.logado = true; 
				this.$router.push('/');
			})
			.catch(error => {
				( window as any).$('body').removeClass('loading');
			});  
		} 
		
	}




	mounted() { 
		const contentwrapper = document.getElementById('contentwrapper');  
		if(contentwrapper){
			contentwrapper.hidden = true ; 
		} 
	}


	destroyed(){   
		const contentwrapper = document.getElementById('contentwrapper');  
		if(contentwrapper){
			contentwrapper.hidden = false ; 
		}  
	}


}
 
