
import 'admin-lte/plugins/fontawesome-free/css/all.min.css';

import 'select2/dist/css/select2.css';

import 'izitoast/dist/css/iziToast.min.css';

import '../css/modalProcessamento.css'; 
// import '../css/googla.map.css'; 

import 'bootstrap/dist/css/bootstrap.css';


import 'admin-lte/dist/css/adminlte.min.css';
// import 'admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css';

import 'datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css';