

















 

	import { Watch, Prop, Component, Vue } from 'vue-property-decorator';


    @Component({
        components: {
             
        },
	}) 
	export default class Modal extends Vue {

		@Prop( ) readonly id!: any
		@Prop( ) readonly titulo!: any
		
	}
 
