 
import { segurancaModel } from './index.model'; 
  
import axios  from 'axios';  
  
const urlLogin = segurancaModel.getUrl() + '/auth' ;  
 
 

function logout() { 
	localStorage.removeItem('user');
	localStorage.clear( ); 
}


function login(id: any, password: any) {

	const data: any = {};  
	data['id'] = id ;  
	data['password'] = password ;  
	return  new Promise((resolve, reject) => {
		axios.post( urlLogin + '/login' , data )
		.then(response => {   
			localStorage.setItem('user', JSON.stringify(response.data.access_token));
			resolve( response.data.access_token);  
		})
		.catch(error => { 
			if ( error.response.status === 401) {
				logout();				
			}
			reject(error.response.data.error);
		})

	});


}

  
  

export const userService = {
	 
	login,
	logout,
   
};

