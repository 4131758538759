/* eslint-disable @typescript-eslint/camelcase */ 

import  { apiModel } from '../api.url'; 
 
 
const url =  apiModel.getApi( ) + '/geral'  ;
const urlResposta =  apiModel.getApiResposta( )   ;
const urlSite =  apiModel.getSiteApi( )  ;
 

const secret_key = 'fgsdf8sfg*bxdfbsd*bxfdsf2323sf**';

function getUrl(  ) { 
	return  url; 
}


function getSiteApi(  ) { 
	return  urlSite; 
}

function getKeyCripto(  ) { 
	return  secret_key; 
}


function getApiResposta(  ) { 
	return  urlResposta; 
}



export const geralModel = { 
	getUrl, 
	getKeyCripto,
	getSiteApi,
	getApiResposta
};
